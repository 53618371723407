import {initJwt} from './jwt-token';

export async function envInit(environment) {
    await initJwt(environment.production);

    environment.host = await getBaseUrl();

    const pluginKey = window.getToken().claims.iss;
    environment.pluginSettingsUrl = `${environment.host}/plugins/servlet/ac/${pluginKey}/jira-external-general-page`;

    if (environment.contentUrl === '') {
        const location = window.location;
        environment.contentUrl = location.protocol + '//' + location.host;
    }

    const AP = window.AP;
    AP.theming.initializeTheming();

    const options = (AP && AP._data && AP._data.options) || {isDialog: false};
    if (options.isDialog) {
        document.body.classList.add('dialog');
    }
}

function getBaseUrl(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        resolve(getQuery().xdm_e[0]);
    });

    function getQuery(): { [key: string]: string[] } {
        const query = {};
        if (location.search) {
            location.search.substr(1).split('&').forEach((item) => {
                const s = item.split('=');
                const k = s[0];
                const v = s[1] && decodeURIComponent(s[1]);
                (query[k] = query[k] || []).push(v);
            });
        }
        return query;
    }
}
