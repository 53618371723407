import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import Utils from '../utils/utils';
import {Observable, zip} from 'rxjs';
import {map} from 'rxjs/operators';
import {JiraService} from '../jira.service';

interface GlobalViewComponentConfig {
    title: string;
    isLite: boolean;
    userIsAdmin: boolean;
    firstIssueId: string;
}

@Component({
    selector: 'app-global-view',
    templateUrl: './global-view.component.html',
    styleUrls: ['./global-view.component.css']
})
export class GlobalViewComponent implements OnInit {
    config$: Observable<GlobalViewComponentConfig>;
    createButtonsDisabled = false;

    constructor(private route: ActivatedRoute,
                private jira: JiraService) {
    }

    ngOnInit() {
        this.config$ = zip(
            this.route.queryParamMap,
            this.jira.searchForIssue('')
        ).pipe(map(([routeQueryParamMap, result]) => {
            const isLite = Utils.isLiteApp(routeQueryParamMap);
            const title = isLite ? 'External Share for Jira - Lite' : 'External Share for Jira';
            const userIsAdmin = Utils.isTrue(routeQueryParamMap.get('bypass_global'));
            const firstIssueId = result?.issues[0]?.id;

            return {
                isLite,
                title,
                userIsAdmin,
                firstIssueId
            };
        }));

        this.jira.resize('es-expand');
    }

    createShare(config: GlobalViewComponentConfig) {
        this.jira.showDialog({
                key: 'share-type-selection-popup',
                customData: {
                    source: 'GLOBAL'
                }
            }
        );
    }

    isAdmin(config: GlobalViewComponentConfig) {
        return config.userIsAdmin;
    }

    hasCreatePermission(config: GlobalViewComponentConfig) {
        return this.isAdmin(config);
    }
}
