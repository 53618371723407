import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {Observable} from 'rxjs';
import {JiraService, Project} from '../../jira.service';
import {ActivityParameters} from '../../components/activity/activity.component';
import {switchMap} from 'rxjs/operators';
import Utils from '../../utils/utils';

@Component({
    selector: 'app-project-activity',
    templateUrl: './project-activity.component.html'
})
export class ProjectActivityComponent implements OnInit {

    $project: Observable<Project>;

    constructor(private route: ActivatedRoute,
                private jira: JiraService) {
    }

    ngOnInit(): void {
        this.$project = Utils.combineParams(this.route.parent).pipe(
            switchMap((params) => {
                return Utils.require(
                    this.project(params),
                    'ProjectActivityComponent',
                    'ngOnInit',
                    `this.project(${JSON.stringify(params)})`
                );
            })
        );
    }

    getActivityParameters(projectId: string): ActivityParameters {
        return {
            level: 'PROJECT',
            projectId,
        };
    }

    project(params: ParamMap): Observable<Project> {
        return this.jira.getProject(params.get('projectId'));
    }
}
