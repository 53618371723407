import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ShareResult} from './share-result';
import {Link, ShareType} from './link';
import {ShareBulkUpdateRequest} from './bulk-edit';
import {Activity} from './activity';
import {ShareUuid} from './share-uuid';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ItemService {

    private static postOptions(operationName: string) {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Operation-Name': operationName,
            })
        };
    }

    private static getOptions(operationName: string) {
        return {
            headers: new HttpHeaders({
                'X-Operation-Name': operationName,
            })
        };
    }

    constructor(private http: HttpClient) {
    }

    get(uuid: string): Observable<{ link: Link; previewUrl: string }> {
        return this.http.get<Link>(`/api/share/${uuid}`, { observe: 'response' })
            .pipe(
                map(response => {
                    const link = response.body as Link;
                    const previewUrl = response.headers.get('Preview-Url');
                    return { link, previewUrl };
                })
            );
    }

    create(issueId: string, projectId: string): Observable<Link> {
        return this.createShare({issueId, projectId, type: 'ISSUE'});
    }

    createJqlFilter(projectId: string): Observable<Link> {
        return this.createShare({projectId, type: 'JQL'});
    }

    createRoadmap(projectId: string): Observable<Link> {
        return this.createShare({projectId, type: 'ROADMAP'});
    }

    update(link: Link): Observable<ShareResult> {
        const url = '/api/share';
        const body = {
            // ID
            id: link.id,
            uuid: link.uuid,
            serviceDeskId: link.serviceDeskId,

            // Updatable fields
            issueKey: link.issueKey,
            issueId: link.issueId,
            projectId: link.projectId,
            board: link.board,
            customBoardConfig: link.customBoardConfig,
            type: link.type,
            name: link.name,
            description: link.description,
            password: link.password,
            expiration: link.expiration,
            unlockSecret: link.unlockSecret,
            namedShareLinkEnabled: link.namedShareLinkEnabled,
            sharedLinkEnabled: link.sharedLinkEnabled,
            linkName: link.linkName,
            showComments: link.showComments,
            allowAddComment: link.allowAddComment,
            showInternalComments: link.showInternalComments,
            allowAddInternalComment: link.allowAddInternalComment,
            showAttachments: link.showAttachments,
            allowAddAttachment: link.allowAddAttachment,
            allowAddPublicAttachment: link.allowAddPublicAttachment,
            showSubtasks: link.showSubtasks,
            showLinkedIssues: link.showLinkedIssues,
            shareSubtasksContent: link.shareSubtasksContent,
            shareLinkedIssuesContent: link.shareLinkedIssuesContent,
            allowWorkflowTransition: link.allowWorkflowTransition,
            showWorklog: link.showWorklog,
            showWebLinks: link.showWebLinks,
            showChangelog: link.showChangelog,
            showApproval: link.showApproval,
            showSmartChecklist: link.showSmartChecklist,
            showIssueChecklist: link.showIssueChecklist,
            allowEditSummary: link.allowEditSummary,
            allowEditDescription: link.allowEditDescription,
            allowCreateNewIssue: link.allowCreateNewIssue,
            allowEditPriority: link.allowEditPriority,
            customFields: link.customFields,
            jql: link.jql,
            selectedUsersConfig: link.selectedUsersConfig,
            samlLoginRequired: link.samlLoginRequired,
            ffProjectShow: link.ffProjectShow,
            ffIssueKeyShow: link.ffIssueKeyShow,
            ffSummaryShow: link.ffSummaryShow,
            ffDescriptionShow: link.ffDescriptionShow,
            ffAssigneeShow: link.ffAssigneeShow,
            ffPriorityShow: link.ffPriorityShow,
            ffStatusShow: link.ffStatusShow,
            ffCreatedDateShow: link.ffCreatedDateShow,
            ffUpdatedDateShow: link.ffUpdatedDateShow,
            ffReporterShow: link.ffReporterShow,
            ffLabelsShow: link.ffLabelsShow,
            ffDueDateShow: link.ffDueDateShow,
            ffTimeTrackingShow: link.ffTimeTrackingShow,
            ffOriginalEstimateShow: link.ffOriginalEstimateShow,
            ffFixVersionsShow: link.ffFixVersionsShow,
            ffVersionsShow: link.ffVersionsShow,
            ffComponentsShow: link.ffComponentsShow,
            ffCustomFieldsShow: link.ffCustomFieldsShow,
            ffCustomFieldsDisplay: link.ffCustomFieldsDisplay,
            showCustomHeader: link.showCustomHeader,
            customHeaderText: link.customHeaderText,
            showCustomFooter: link.showCustomFooter,
            customFooterText: link.customFooterText,
            cardLayoutId: link.cardLayoutId,
            filterShareFields: link.filterShareFields,
            issueLayoutId: link.issueLayoutId,
            allowCommentsWithRestrictions: link.allowCommentsWithRestrictions,
            groupsAndRoles: link.groupsAndRoles,
            quickFilters: link.quickFilters,
            hiddenFilters: link.hiddenFilters
        };
        const options = { ...ItemService.postOptions('Update share'), observe: 'response' as const };
        return this.http.put<ShareResult>(url, body, options).pipe(
            map(response => {
                const shareResult = response.body as ShareResult;
                const previewUrl = response.headers.get('Preview-Url');
                return { ...shareResult, previewUrl };
            })
        );
    }

    regenerateUnlockSecret(uuid: string): Observable<string> {
        const url = `/api/share/${uuid}/regenerateUnlockSecret`;
        const options = ItemService.postOptions('Regenerate Unlock Secret');
        return this.http.post<string>(url, null, options);
    }

    bulkUpdate(request: ShareBulkUpdateRequest): Observable<number> {
        const url = '/api/share-update';
        const options = ItemService.postOptions('Update share');
        return this.http.post<number>(url, request, options);
    }

    delete(links: ShareUuid[]): Observable<number> {
        const url = '/api/share-delete/id';
        const body = (links as (Link | Activity)[]).map(it => it.uuid);
        const options = ItemService.postOptions('Delete share');
        return this.http.post<number>(url, body, options);
    }

    deleteAll() {
        const url = '/api/share-delete/all';
        const options = ItemService.postOptions('Delete all shares');
        return this.http.post<number>(url, options);
    }

    sendMail(email: any): Observable<any> {
        const url = `/api/mail`;
        const body = email;
        const options = ItemService.postOptions('Send Mail');
        return this.http.post<any>(url, body, options);
    }

    getMail(uuid: string) {
        const url = `/api/mail?uuid=${uuid}`;
        const options = ItemService.getOptions('Get mail data');
        return this.http.get<any>(url, options);
    }

    createShare(body: { name?: string, projectId?: string, issueId?: string, type: ShareType }) {
        const url = '/api/share';
        const options = ItemService.postOptions('Create share');
        return this.http.post<Link>(url, body, options);
    }
}
