import {Component, Input} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-quick-filters-list',
    templateUrl: './quick-filters-list.component.html',
    styleUrls: ['./quick-filters-list.component.css']
})
export class QuickFiltersListComponent {
    @Input() parentForm: FormGroup;

    get quickFilters(): FormArray {
        return this.parentForm.get('quickFilters') as FormArray;
    }

    removeQuickFilter(index: number) {
        this.quickFilters.removeAt(index);
    }

    drop(event: CdkDragDrop<FormGroup[]>) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.quickFilters.controls, event.previousIndex, event.currentIndex);
            this.quickFilters.updateValueAndValidity();
        }
    }
}
