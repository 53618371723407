import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TooltipComponentDirective} from './tooltip-component.directive';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppMultiSelectComponent} from '../components/app-multi-select.component';
import {NiceDateTimeFormatPipe, ShortNiceDateTimeFormatPipe} from '../components/nice-date-time-format-pipe';
import {AppCheckboxComponent} from '../components/app-checkbox.component';
import {AppRadioComponent} from '../components/app-radio.component';
import {AppRadioGroupDirective} from '../components/app-radio-group.directive';
import {LinkToUserComponent} from '../components/link-to-user.component';
import {LinkToIssueComponent} from '../components/link-to-issue.component';
import {TableSortHeaderDirective} from '../components/table-sort-header.directive';
import {ShareActionRowComponent} from '../share-action-row/share-action-row.component';
import {IconsModule} from '../icons/icons.module';
import {ToggleComponent} from '../components/toggle/toggle.component';
import {LinkToProjectSettingsComponent} from '../components/link-to-project-settings.component';
import {PeriodComponent} from '../components/period/period.component';
import {GlobalSettingsLinkDirective} from '../components/global-settings-link/global-settings-link.directive';
import {BetterSelectComponent} from '../components/better-select/better-select.component';
import {ShareLinkComponent} from '../components/share-link/share-link.component';
import {AutofocusDirective} from '../components/autofocus.directive';
import {ShortDescriptionPipe} from '../components/short-description-pipe';
import {ImageUploadComponent} from '../components/image-upload/image-upload.component';
import {TrimInputDirective} from './trim-input.directive';
import {FileUploadComponent} from '../components/file-upload/file-upload.component';
import {UrlEncodePipe} from '../components/url-encode-pipe';
import {CustomizeColumnsComponent} from '../components/customize-columns/customize-columns.component';
import {ColumnCheckboxComponent} from '../components/customize-columns/column-checkbox.component';
import {ShareListTableComponent} from '../components/share-list-table/share-list-table.component';
import {ActivityComponent} from '../components/activity/activity.component';
import {AppSelectComponent} from '../components/app-select.component';
import {LinkToBoardComponent} from '../components/link-to-board.component';
import {DateTimePickerDirective} from '../components/date-time-picker.directive';
import {
    ShareContentConfigurationComponent
} from '../components/share-content-configuration/share-content-configuration.component';
import {
    CardLayoutConfigurationComponent
} from '../components/card-layout-configuration/card-layout-configuration.component';
import {SafeJsonPipe} from '../components/safe-json-pipe';
import {CamelCaseSplitPipe} from '../components/camel-case-split-pipe';
import {SnakeCaseSplitPipe} from '../components/snake-case-split-pipe';
import {
    IssueLayoutConfigurationComponent
} from '../components/issue-layout-configuration/issue-layout-configuration.component';

@NgModule({
    declarations: [
        TooltipComponentDirective,
        AppMultiSelectComponent,
        NiceDateTimeFormatPipe,
        ShortNiceDateTimeFormatPipe,
        AppCheckboxComponent,
        AppRadioComponent,
        AppRadioGroupDirective,
        LinkToUserComponent,
        LinkToIssueComponent,
        LinkToProjectSettingsComponent,
        TableSortHeaderDirective,
        ShareActionRowComponent,
        ToggleComponent,
        PeriodComponent,
        GlobalSettingsLinkDirective,
        BetterSelectComponent,
        ShareLinkComponent,
        AutofocusDirective,
        ShortDescriptionPipe,
        ImageUploadComponent,
        TrimInputDirective,
        FileUploadComponent,
        CamelCaseSplitPipe,
        SnakeCaseSplitPipe,
        UrlEncodePipe,
        CustomizeColumnsComponent,
        ColumnCheckboxComponent,
        ShareListTableComponent,
        ActivityComponent,
        AppSelectComponent,
        LinkToBoardComponent,
        DateTimePickerDirective,
        ShareContentConfigurationComponent,
        CardLayoutConfigurationComponent,
        SafeJsonPipe,
        IssueLayoutConfigurationComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        IconsModule
    ],
    exports: [
        TooltipComponentDirective,
        AppMultiSelectComponent,
        NiceDateTimeFormatPipe,
        AppCheckboxComponent,
        AppRadioComponent,
        AppRadioGroupDirective,
        LinkToUserComponent,
        LinkToIssueComponent,
        LinkToProjectSettingsComponent,
        TableSortHeaderDirective,
        ShortNiceDateTimeFormatPipe,
        ShareActionRowComponent,
        ToggleComponent,
        PeriodComponent,
        GlobalSettingsLinkDirective,
        BetterSelectComponent,
        ShareLinkComponent,
        AutofocusDirective,
        ShortDescriptionPipe,
        ImageUploadComponent,
        FileUploadComponent,
        TrimInputDirective,
        CamelCaseSplitPipe,
        SnakeCaseSplitPipe,
        UrlEncodePipe,
        CustomizeColumnsComponent,
        ColumnCheckboxComponent,
        ShareListTableComponent,
        ActivityComponent,
        AppSelectComponent,
        LinkToBoardComponent,
        DateTimePickerDirective,
        ShareContentConfigurationComponent,
        CardLayoutConfigurationComponent,
        SafeJsonPipe,
        IssueLayoutConfigurationComponent
    ]
})
export class BaseModule {
}
