import {ViewItem} from '../view-item';
import {PeriodEnum} from './period';
import {Config, FilterFieldConfig} from './config';
import {SelectedUsersConfig} from './selected-users-config';
import {ShareFormFilterField} from '../components/share-form-filter-fields/share-form-filter-field';
import {FilterCriteria} from '../components/app-table.component';
import {CustomBoardConfig} from '../share-form/custom-board-config';
import {QuickFilter} from '../app.service';
import {ShareUuid} from './share-uuid';

export type ShareType = 'BOARD' | 'JQL' | 'ROADMAP' | 'ISSUE';

export class Link implements ShareUuid {
    id?: string;
    url: string;
    baseShareUrl?: string;
    baseUrl?: string;
    projectId?: string;
    issueKey?: string;
    issueId?: string;
    board?: ViewItem;
    customBoardConfig?: CustomBoardConfig;
    serviceDeskId: string;
    uuid?: string;
    name?: string;
    jql?: string;
    description?: string;
    customDomain?: string;
    status?: ShareStatus;
    type: ShareType;
    password?: string;
    unlockSecret?: string;
    expiration?: number;
    namedShareLinkEnabled?: boolean;
    sharedLinkEnabled?: boolean;
    linkName?: string;
    showComments?: boolean;
    allowAddComment?: boolean;
    showInternalComments?: boolean;
    allowAddInternalComment?: boolean;
    showAttachments?: boolean;
    allowAddAttachment?: boolean;
    allowAddPublicAttachment?: boolean;
    showSubtasks?: boolean;
    showLinkedIssues?: boolean;
    shareSubtasksContent?: boolean;
    shareLinkedIssuesContent?: boolean;
    allowWorkflowTransition?: boolean;
    showWorklog?: boolean;
    showWebLinks?: boolean;
    showChangelog?: boolean;
    showApproval?: boolean;
    showSmartChecklist?: boolean;
    smartCheckListOptionVisible?: boolean;
    showIssueChecklist?: boolean;
    issueCheckListOptionVisible?: boolean;
    allowEditSummary?: boolean;
    allowEditDescription?: boolean;
    allowCreateNewIssue?: boolean;
    allowEditPriority?: boolean;
    customFields: ViewItem[];
    created?: string;
    createdBy?: string;
    updated?: string;
    updatedBy?: string;
    opened?: number;
    projectEnabled: boolean;

    availableCustomFields: ViewItem[];
    availableGroupsAndRoles: ViewItem[];
    availableBoards: ViewItem[];
    availableProjects: ViewItem[];

    application: 'lite' | 'pro';
    mailSendOption: 'on' | 'off';

    passwordRequired?: boolean;
    expirationRequired?: boolean;
    expirationUnit?: PeriodEnum;
    expirationCount?: number;

    selectedUsersConfig?: SelectedUsersConfig;
    samlLoginRequired: boolean;

    parent: Config;
    previewJwt?: string;

    ffProjectShow: boolean;
    ffIssueKeyShow: boolean;
    ffSummaryShow: boolean;
    ffDescriptionShow: boolean;
    ffAssigneeShow: boolean;
    ffPriorityShow: boolean;
    ffStatusShow: boolean;
    ffCreatedDateShow: boolean;
    ffUpdatedDateShow: boolean;
    ffReporterShow: boolean;
    ffLabelsShow: boolean;
    ffDueDateShow: boolean;
    ffTimeTrackingShow: boolean;
    ffOriginalEstimateShow: boolean;
    ffFixVersionsShow: boolean;
    ffVersionsShow: boolean;
    ffComponentsShow: boolean;
    ffCustomFieldsShow: boolean;
    ffCustomFieldsDisplay: Array<string>;

    showCustomHeader: boolean;
    customHeaderText: string;
    showCustomFooter: boolean;
    customFooterText: string;
    cardLayoutId?: string;
    filterShareFields: ShareFormFilterField[];
    issueLayoutId?: string;
    allowCommentsWithRestrictions?: boolean;
    groupsAndRoles: ViewItem[];
    quickFilters: QuickFilter[];
    mailTo?: string;
    hiddenFilters?: ViewItem[];
}

export class LinkFilterCriteria implements FilterCriteria {
    status?: string;
    issueId?: string;
    projectId?: string;
    userId?: string;
    query?: string;
}

export class ShareStatus {
    statusType: 'INACTIVE' | 'ACTIVE';
    disabled: boolean;
    disabledByParent: boolean;
    expired: boolean;
}

export class ViewLink {
    id?: string;
    projectId?: string;
    issueKey?: string;
    issueId?: string;
    board?: ViewItem;
    customBoardConfig?: CustomBoardConfig;
    serviceDeskId: string;
    uuid?: string;
    name?: string;
    description?: string;
    status?: ShareStatus;
    type: ShareType;
    hasPassword: boolean;
    password?: string;
    unlockSecret?: string;
    customDomain?: string;
    filterFieldConfig?: FilterFieldConfig;

    expirationEnabled: boolean;
    expirationType: 'DATE_TIME' | 'SELECTABLE';
    expirationPeriod: { count: number, unit: PeriodEnum };
    expirationDate: number;

    namedShareLinkEnabled?: boolean;
    sharedLinkEnabled?: boolean;
    linkName?: string;
    showComments?: boolean;
    allowAddComment?: boolean;
    showInternalComments?: boolean;
    allowAddInternalComment?: boolean;
    showAttachments?: boolean;
    allowAddAttachment?: boolean;
    allowAddPublicAttachment?: boolean;
    showSubtasks?: boolean;
    showLinkedIssues?: boolean;
    shareSubtasksContent?: boolean;
    shareLinkedIssuesContent?: boolean;
    allowWorkflowTransition?: boolean;
    showWorklog?: boolean;
    showWebLinks?: boolean;
    showChangelog?: boolean;
    showApproval?: boolean;
    showSmartChecklist?: boolean;
    smartCheckListOptionVisible?: boolean;
    showIssueChecklist?: boolean;
    issueCheckListOptionVisible?: boolean;
    allowEditSummary?: boolean;
    allowEditDescription?: boolean;
    allowEditPriority?: boolean;
    allowCreateNewIssue?: boolean;
    created?: string;
    createdBy?: string;
    updated?: string;
    updatedBy?: string;
    opened?: number;
    customFields: ViewItem[];
    allowCommentsWithRestrictions?: boolean;
    groupsAndRoles: ViewItem[];
    projectEnabled: boolean;

    application: 'lite' | 'pro';
    mailSendOption: 'on' | 'off';

    allowSelectedUsers?: boolean;
    allowSelectedUsersNotification?: boolean;
    selectedUsers?: [];
    selectedUsersEmailDomains?: string;

    selectedUsersConfig?: SelectedUsersConfig;
    samlLoginRequired: boolean;

    ffProjectShow: boolean;
    ffIssueKeyShow: boolean;
    ffSummaryShow: boolean;
    ffDescriptionShow: boolean;
    ffAssigneeShow: boolean;
    ffPriorityShow: boolean;
    ffStatusShow: boolean;
    ffCreatedDateShow: boolean;
    ffUpdatedDateShow: boolean;
    ffReporterShow: boolean;
    ffLabelsShow: boolean;
    ffDueDateShow: boolean;
    ffTimeTrackingShow: boolean;
    ffOriginalEstimateShow: boolean;
    ffFixVersionsShow: boolean;
    ffVersionsShow: boolean;
    ffComponentsShow: boolean;
    ffCustomFieldsShow: boolean;
    ffCustomFieldsDisplay: Array<string>;

    showCustomHeader: boolean;
    customHeaderText: string;
    showCustomFooter: boolean;
    customFooterText: string;
    cardLayoutId?: ViewItem;
    filterShareFields: ShareFormFilterField[];
    issueLayoutId?: ViewItem;
    quickFilters: QuickFilter[];
    hiddenFilters: ViewItem[];
}
