import {AbstractControl, FormArray, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

export function uniqueQuickFilterNameValidator(): ValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> | null => {
        if (!control.parent) {
            return of(null);
        }

        const quickFilters = control.parent.get('quickFilters') as FormArray;
        if (!quickFilters || !(quickFilters instanceof FormArray)) {
            return of(null);
        }

        let name = control.value;
        if (name instanceof Object) {
            name = name.text;
        }
        if (!name || name.trim().length === 0) {
            return of(null);
        }
        return of(quickFilters.controls.some(qf => qf.get('name').value.toLowerCase() === name.toLowerCase())).pipe(
            map(nameExists => nameExists ? {duplicateName: true} : null)
        );
    };
}
