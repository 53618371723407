import {Component, Input} from '@angular/core';
import {Link} from '../../../base/link';
import {ActivityParameters} from '../../../components/activity/activity.component';


@Component({
    selector: 'app-share-activity',
    templateUrl: './share-form-preview-share-activity.component.html',
    styleUrls: ['./share-form-preview-share-activity.component.css']
})
export class ShareFormPreviewShareActivityComponent {
    @Input() link: Link;

    getActivityParameters(): ActivityParameters {
        return {
            level: 'SHARE',
            projectId: this.link.projectId,
            issueId: this.link.issueId,
            type: this.link.type,
            shareId: this.link.id,
            name: this.link.name
        };
    }

}
