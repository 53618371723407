import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Column} from '../customize-columns/customize-columns.component';
import {ServerDataSource} from '../app-table.component';
import Utils from '../../utils/utils';
import {Link, LinkFilterCriteria} from '../../base/link';

@Component({
    selector: 'app-share-list-table',
    templateUrl: './share-list-table.component.html',
    styleUrls: ['./share-list-table.component.css']
})
export class ShareListTableComponent implements OnInit, OnChanges {

    @Input() columnForm: FormGroup;
    @Input() columns: Column[];
    @Input() moreThanOneItemIsSelected: boolean;
    @Input() deleteSelected: boolean;
    @Input() cookiesName: string;
    @Input() source: ServerDataSource<Link, LinkFilterCriteria>;
    @Input() elements: any[];
    @Output() emitMoreThanOneItem = new EventEmitter();
    columnOrder: FormControl = new FormControl('');
    tableColumns: Column[];
    draggingIndex: number;

    ngOnInit(): void {
        this.columnOrder.setValue(this.tableColumns);
        AP.cookie.read(this.cookiesName, (fromCookie) => {
            if (fromCookie) {
                const values = JSON.parse(fromCookie);
                this.columnOrder.setValue(values);
                this.columns = this.columnOrder.value.sort((a, b) => a.index - b.index);
                this.tableColumns = this.columns;
            } else {
                this.tableColumns = this.columns;
            }
        });

        this.columnOrder.valueChanges.subscribe(columns => {
            AP.cookie.save(this.cookiesName, JSON.stringify(columns), 365);
        });
    }

    emitMoreThanOneItemIsSelected(value: boolean) {
        this.emitMoreThanOneItem.emit(value);
    }

    private _reorderItem(fromIndex: number, toIndex: number): void {
        const itemToBeReordered = this.tableColumns.splice(fromIndex, 1)[0];
        this.tableColumns.splice(toIndex, 0, itemToBeReordered);
        this.draggingIndex = toIndex;
        this.tableColumns = this.tableColumns.map((column, index) => {
            column.index = index;
            return column;
        });
        this.columnOrder.setValue(this.tableColumns);
    }

    onDragStart(index: number): void {
        this.draggingIndex = index;
    }

    onDragEnter(index: number): void {
        if (this.draggingIndex !== index) {
            this._reorderItem(this.draggingIndex, index);
        }
    }

    onDragEnd(): void {
        this.draggingIndex = undefined;
    }

    constructor() {
    }

    isColumnHidden(name): boolean {
        if (!this.columnForm || !this.columnForm.get('columns').value) {
            return true;
        }
        return !this.columnForm.get('columns').value.find(c => c === name);
    }

    copyToClipboard(value: any) {
        Utils.copyToClipboard(value);
    }

    selectAll(on: boolean, elements: any[]) {
        elements.forEach(it => it._delete = on);
        this.updateDeleteAll(elements);
    }

    updateDeleteAll(elements: any[]) {
        this.moreThanOneItemIsSelected = elements.contains(el => el._delete);
        this.deleteSelected = elements.every(el => el._delete);
        this.emitMoreThanOneItemIsSelected(this.moreThanOneItemIsSelected);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.elements) {
            this.deleteSelected = false;
            this.emitMoreThanOneItemIsSelected(false);
        }
    }

    getInactiveStatusTooltip(status): string {
        const messages = [];

        if (status.disabled) {
            messages.push('Disabled');
        }

        if (status.disabledByParent) {
            messages.push('Disabled by parent');
        }

        if (status.expired) {
            messages.push('Expired');
        }
        return messages.length ? messages.join(', ') : 'Project is inactive';
    }
}
