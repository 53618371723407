import {Component, OnInit} from '@angular/core';
import {finalize} from 'rxjs/operators';
import {JiraService} from '../../jira.service';
import {Observable} from 'rxjs';
import {ServerDataSource} from '../../components/app-table.component';
import {HttpHeaders} from '@angular/common/http';
import {DefaultConfigView, GlobalConfig, GlobalConfigView, GlobalConfigViewFilterCriteria} from '../../base/config';
import {LoaderService} from '../../loader.service';
import {AppService} from '../../app.service';

@Component({
    selector: 'app-project-schema-settings-list',
    templateUrl: './project-schema-settings.component.html',
    styleUrls: ['./project-schema-settings.component.css']
})
export class ProjectSchemaSettingsComponent implements OnInit {
    private refreshEventName = 'config-update';
    private refreshDefaultEventName = 'default-config-update';
    public defaultConfig: Observable<DefaultConfigView>;

    deleteAll = false;

    moreThanOneItemIsSelected = false;

    constructor(private jira: JiraService,
                private loader: LoaderService,
                public source: ServerDataSource<GlobalConfigView, GlobalConfigViewFilterCriteria>,
                private app: AppService) {
    }

    ngOnInit(): void {
        const refresh = this.jira.observeEvent(this.refreshEventName);

        refresh.subscribe(() => {
            this.source.refresh();
        });

        const refreshDefault = this.jira.observeEvent(this.refreshDefaultEventName);

        refreshDefault.subscribe(() => {
            this.refreshDefaultConfig();
        });
        this.refreshDefaultConfig();

        this.source.reload({
            endPoint: `/api/config/global`,
            defaultSort: 'created',
            defaultSortOrder: 'desc',
            defaultLimit: 10,
            headers: new HttpHeaders({
                'X-Operation-Name': 'Fetching schemas',
            })
        });
    }

    private refreshDefaultConfig() {
        this.defaultConfig = this.app.getDefaultConfig();
    }

    selectAll(on: boolean, elements: any[]) {
        elements.forEach(it => it.item.config._delete = on);
        this.updateDeleteAll(elements);
    }

    updateDeleteAll(elements: any[]) {
        this.moreThanOneItemIsSelected = elements.contains(el => el.item.config._delete);
        this.deleteAll = elements.every(el => el.item.config._delete);
    }

    doDeleteAll(elements: GlobalConfigView[]) {
        const toDelete = elements.filter((it: any) => it.item.config._delete).map(it => it.item) as GlobalConfig[];
        if (toDelete.length > 0) {
            this.confirmDeletion(toDelete);
        }
    }

    editDefault() {
        this.jira.showDialog({
                key: 'schema-edition-popup',
                customData: {
                    type: 'DEFAULT'
                }
            }
        );
    }

    createNew() {
        this.jira.showDialog({
                key: 'schema-edition-popup',
                customData: {
                    isNew: true,
                    type: 'GLOBAL'
                }
            }
        );
    }

    edit(el: GlobalConfig) {

        this.jira.showDialog({
                key: 'schema-edition-popup',
                customData: {
                    id: el.id,
                    type: 'GLOBAL'
                }
            }
        );
    }

    confirmDeletion(el: GlobalConfig | GlobalConfig[]) {
        this.jira.showDialog({
                key: 'confirm-delete',
                customData: {
                    title: 'Delete selected Project Config?',
                }
            }, (result) => {
                if (result.deleted) {
                    this.loader.fullscreen(true);

                    const ids = Array.isArray(el) ? el.map(it => it.id) : [el.id];
                    this.app.deleteGlobalConfig(ids)
                        .pipe(finalize(() => this.loader.fullscreen(false)))
                        .subscribe(() => {
                            this.jira.emitEvent(this.refreshEventName);
                        });
                    this.moreThanOneItemIsSelected = false;
                    this.deleteAll = false;
                }
            }
        );
    }
}
