import {Component, Input, OnInit, Renderer2} from '@angular/core';
import {FormGroup} from '@angular/forms';
import Utils from '../../utils/utils';

@Component({
    selector: 'app-customize-columns',
    templateUrl: './customize-columns.component.html',
    styleUrls: ['./customize-columns.component.css']
})
export class CustomizeColumnsComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() columns: Column[];
    @Input() name: string;

    customizeColumns = false;

    constructor(private renderer: Renderer2) {
    }

    ngOnInit(): void {
        AP.cookie.read(this.name, (fromCookie) => {
            if (fromCookie) {
                const values = JSON.parse(fromCookie);
                if (Utils.isNotEmptyArray(values)) {
                    this.columns.forEach(column => {
                        column.selected = !!values.find(i => i === column.value);
                    });
                    this.form.get('columns').setValue(values);
                } else {
                    this.setDefaultValues();
                }
            } else {
                this.setDefaultValues();
            }
        });

        this.form.get('columns').valueChanges.subscribe(columns => {
            this.columns.map(i => i.value).forEach(column => {
                const display = columns.find(c => c === column) ? '' : 'none';
                const nodes = document.querySelectorAll('[data-name=' + column + ']');
                nodes.forEach(node => {
                    this.renderer.setStyle(node, 'display', display);
                    if (display !== 'none') {
                        node.classList.remove('hidden');
                    }
                });
            });
            AP.cookie.save(this.name, JSON.stringify(columns), 365);
        });
    }

    showCustomizedColumnsComponent(show) {
        this.customizeColumns = show;
    }

    showAllCustomizedColumns() {
        const toShow = this.columns.map(i => i.value);
        this.columns = this.columns.map(column => {
            column.selected = true;
            return column;
        });
        this.form.get('columns').setValue(toShow);
    }

    columnsValue(column) {
        return column.value;
    }

    columnsText(column) {
        return column.name;
    }

    private setDefaultValues() {
        const defaultValues = this.columns.filter(c => c.selected).map(i => i.value);
        this.form.get('columns').setValue(defaultValues);
    }
}

export class Column {
    constructor(public value: string,
                public name: string,
                public selected: boolean,
                public index: number) {
    }

    public static create(...columns: [string, string, boolean][]): Column[] {
        return columns.map((column, index) => new Column(column[0], column[1], column[2], index));
    }
}
