import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {ItemService} from '../../base/item.service';
import {Observable, zip} from 'rxjs';
import {finalize, map, switchMap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {JiraService} from '../../jira.service';
import {LoaderService} from '../../loader.service';
import CustomValidators from '../../utils/custom-validators';
import Utils from '../../utils/utils';

@Component({
    selector: 'app-email-form',
    templateUrl: './email-form.component.html',
    styleUrls: ['./email-form.component.css']
})
export class EmailFormComponent implements OnInit {
    emailBlurred: { [key: string]: boolean } = {
        to: false,
        cc: false,
        bcc: false
    };
    config$: Observable<any>;
    form = new FormGroup({
        shareUuid: new FormControl(null), // needed by Server
        to: new FormControl(null, [Validators.required, CustomValidators.email]),
        cc: new FormControl(null, CustomValidators.email),
        bcc: new FormControl(null, CustomValidators.email),
        subject: new FormControl(null),
        content: new FormControl(null, [Validators.required])
    });

    constructor(private service: ItemService,
                private jira: JiraService,
                private route: ActivatedRoute,
                private loader: LoaderService,
                private router: Router) {
    }

    ngOnInit(): void {
        this.config$ = this.jira.getCustomData<any>().pipe(
            switchMap((data: any) => {
                return zip(
                    this.service.getMail(data.uuid),
                );
            }),
            map(([config]) => {
                this.form.patchValue(config);
                return Object.assign(config);
            })
        );
    }

    send() {
        this.executeSend().then(() => {
            this.jira.closeDialog();
            AP.flag.create({
                title: 'External Share | Success',
                body: 'External Share link has been successfully sent',
                type: 'success',
                close: 'auto'
            });
        });
    }

    executeSend() {
        return new Promise((resolve) => {
            this.loader.fullscreen(true);
            this.service.sendMail(this.form.value)
                .pipe(finalize(() => this.loader.fullscreen(false)))
                .subscribe(() => {
                    resolve();
                });
        });
    }

    close() {
        this.jira.closeDialog();
    }

    edit() {
        this.router.navigate(['/share'], {queryParamsHandling: 'merge'});
    }

    shouldDisplayError(control: AbstractControl): boolean {
        return Utils.shouldDisplayError(control);
    }

    validateEmail(controlName: string) {
        if (this.form.get(controlName).value !== null && this.form.get(controlName).value !== '') {
            this.emailBlurred[controlName] = true;
        }
    }

    resetEmailBlurred(controlName: string) {
        this.emailBlurred[controlName] = false;
    }
}
