import {Component, OnInit} from '@angular/core';
import {forkJoin, Observable, of, zip} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {ServerDataSource} from '../../components/app-table.component';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ShareActionService} from '../../share-action.service';
import {JiraService} from '../../jira.service';
import {map, switchMap} from 'rxjs/operators';
import Utils from '../../utils/utils';
import {Link, LinkFilterCriteria} from '../../base/link';
import {Column} from 'src/app/components/customize-columns/customize-columns.component';
import {CsvData, ExportCsv} from '../../components/exportCsv/exportCsv';
import {AppService} from '../../app.service';
import {AtlassianUser} from '../../atlassian-user';
import {LoaderService} from '../../loader.service';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-global-view-list',
    templateUrl: './global-view-list.component.html',
    styleUrls: ['./global-view-list.component.css']
})
export class GlobalViewListComponent implements OnInit {

    constructor(private http: HttpClient,
                private app: AppService,
                private shareActions: ShareActionService,
                private jira: JiraService,
                private loader: LoaderService,
                private datePipe: DatePipe) {
    }

    features = window.getToken().features;

    filterForm = new FormGroup({
        textSearch: new FormControl('status: active ')
    });

    additionalFilterForm = new FormGroup({
        createdByMe: new FormControl(false)
    });

    columnForm = new FormGroup({
        columns: new FormControl('')
    });

    columns = Column.create(
        ['share-type', 'Share type', true],
        ['issue-key', 'Issue key', true],
        ['link-name', 'Link name', true],
        ['created-by', 'Created by', true],
        ['created', 'Created', true],
        ['last-updated-by', 'Last updated by', true],
        ['last-updated', 'Last updated', true],
        ['opened', 'Opened', true],
        ['password', 'Password', true],
        ['expiration', 'Expiration', true],
        ['link-status', 'Link status', true],
        ['selected-users-emails', 'Emails', false],
        ['selected-users-domains', 'Domains', false],
        ['options', 'Options', true],
        ['actions', 'Actions', true]
    );

    deleteAll = false;

    source = new ServerDataSource<Link, LinkFilterCriteria>(this.http);
    moreThanOneItemIsSelected = false;
    showFilterInformation = false;

    private textSearch: string;

    private baseInstanceUrl: string;

    displayErrorCsvMessage = false;
    header: string[] = [
        'Share type', 'Project ID', 'Issue key',
        'Issue ID', 'Issue', 'Share UUID', 'Link name', 'Share link url',
        'Created by', 'Created', 'Last updated by',
        'Last updated', 'Opened', 'Password', 'Expiration',
        'Link status', 'Emails', 'Domains'
    ];

    ngOnInit() {
        const refresh = this.jira.observeEvent('share-update');
        refresh.subscribe((data) => {
            this.source.refresh();
        });

        this.jira.getBaseUrl().subscribe(value => {
            this.baseInstanceUrl = value;
        });

        this.source.reload({
            endPoint: `/api/share`,
            defaultSort: 'created',
            defaultSortOrder: 'desc',
            defaultLimit: 20,
            filter: {query: this.filterForm.get('textSearch').value},
            headers: new HttpHeaders({
                'X-Operation-Name': 'Fetching shares',
            })
        });

        this.filterForm.get('textSearch').valueChanges.subscribe(value => {
            if (value !== 'created_by: me' && this.additionalFilterForm.get('createdByMe').value) {
                this.textSearch = value;
                this.additionalFilterForm.get('createdByMe').setValue(false);
            }
        });

        this.additionalFilterForm.get('createdByMe').valueChanges.subscribe(value => {
            let textSearchToSet = 'status: active';
            if (this.textSearch) {
                textSearchToSet = this.textSearch;
                this.textSearch = undefined;
            } else if (value) {
                textSearchToSet = 'created_by: me';
            }
            this.filterForm.get('textSearch').setValue(textSearchToSet);
            this.doFilter();
        });
    }

    updateMoreThanOneItemSelected(value: boolean) {
        this.moreThanOneItemIsSelected = value;
    }

    exportCSV(): void {
        this.displayErrorCsvMessage = false;
        const header: string[] = this.header;
        const baseUrl: string = this.baseInstanceUrl;

        let searchQuery = this.filterForm.get('textSearch').value;
        searchQuery = searchQuery.split(' ').join('%20');

        this.loader.fullscreen(true);

        getAllSharesArray(this.app).then(sharesArray => {
            if (sharesArray.length === 0) {
                this.loader.fullscreen(false);
                this.displayErrorCsvMessage = true;
                return false;
            }
            of(sharesArray).pipe(
                switchMap((array) => {
                    const users$: Observable<AtlassianUser>[] = [];
                    const usersIds = new Set(array.map(el => el.createdBy)
                        .concat(array.filter(id => id.updatedBy !== null)
                            .map(el => el.updatedBy)));

                    usersIds.forEach(userId => {
                        const user$: Observable<AtlassianUser> = Utils.require(this.jira.requestUser(userId),
                            'GlobalViewListComponent', 'exportCSV', `this.jira.requestUser(${userId})`);
                        users$.push(user$);
                    });

                    return zip(of(array), forkJoin(users$));
                }),
                map(([array, user]) => {
                    const users: Map<string, string> = new Map(user.map(i => [i.accountId, i.displayName]));
                    const modifiedArray: Link[] = array.map(el => {
                        el.createdBy = users.get(el.createdBy);
                        el.updatedBy = users.get(el.updatedBy);
                        return el;
                    });
                    return modifiedArray;
                })
            ).subscribe((a) => {
                const rows = getRows(a);
                const csvData: CsvData = {rows, header};
                ExportCsv.exportToCsv('global_share_list', csvData);
                this.loader.fullscreen(false);
            });
        });

        function getAllSharesArray(app: AppService): Promise<Array<Link>> {
            return new Promise(resolve => {
                const query = {projectId: '', userQuery: searchQuery};
                app.getAllShares(query).then(elements => {
                    let sharesArray: Array<Link> = [];
                    for (const array of elements) {
                        sharesArray = sharesArray.concat(array);
                    }
                    resolve(sharesArray);
                });
            });
        }

        const datePipe = this.datePipe;

        function getRows(sharesArray: Link[]): string[][] {
            const rows: string[][] = [];
            for (const data of sharesArray) {
                const row: string[] = [
                    data.type,
                    data.projectId,
                    data.issueKey,
                    data.issueId,
                    data.issueKey ? baseUrl + '/browse/' + data.issueKey : '',
                    data.uuid,
                    data.name,
                    data.url,
                    data.createdBy,
                    Utils.toIsoDate(data.created, datePipe),
                    data.updatedBy,
                    Utils.toIsoDate(data.updated, datePipe),
                    data.opened.toString(),
                    data.password,
                    Utils.toIsoDate(data.expiration, datePipe),
                    data.status.statusType,
                    data.selectedUsersConfig ? data.selectedUsersConfig.emails : '',
                    data.selectedUsersConfig ? data.selectedUsersConfig.domains : ''
                ];
                rows.push(row);
            }
            return rows;
        }
    }

    copyToClipboard(value: any) {
        Utils.copyToClipboard(value);
    }

    doDeleteAll(elements: any[]) {
        const toDelete = elements.filter(it => it._delete) as Link[];
        this.shareActions.deleteMultiple(toDelete);
    }

    doEditAll(elements: any[], query: string) {
        const toEdit = elements.filter(it => it._delete) as Link[];
        this.shareActions.bulkEdit(toEdit, {query});
    }

    filter(event: KeyboardEvent | MouseEvent) {
        if ('code' in event) {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                this.doFilter();
            }
        } else {
            const query: string = this.filterForm.value.textSearch;
            this.source.updateFilter({query});
        }
    }

    private doFilter() {
        const query: string = this.filterForm.value.textSearch;
        this.source.updateFilter({query});
    }
}
