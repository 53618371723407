import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-share-editor-tab',
    templateUrl: './share-editor-tab.component.html',
    styleUrls: ['./share-editor-tab.component.css']
})
export class ShareEditorTabComponent {
    @Input() icon: string;
    @Input() subtitleIcon: string;
    @Input() title: string;
    @Input() subtitle: string;

    isActive = false;

    toggleTab() {
        this.isActive = !this.isActive;
    }
}

