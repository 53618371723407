import {Component, Input} from '@angular/core';
import {ShareActionService} from '../share-action.service';
import {ShareUuid} from '../base/share-uuid';

@Component({
    selector: 'app-share-action-row',
    templateUrl: './share-action-row.component.html',
    styleUrls: ['./share-action-row.component.css']
})
export class ShareActionRowComponent {

    @Input() share: ShareUuid;

    constructor(private shareActions: ShareActionService) {
    }

    edit(link: ShareUuid) {
        this.shareActions.edit(link);
    }

    view(link: ShareUuid) {
        this.shareActions.view(link);
    }

    confirmDeletion(link: ShareUuid) {
        this.shareActions.deleteSingle(link);
    }
}
