import {Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {isObservable, Observable} from 'rxjs';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {catchError} from 'rxjs/operators';
import {RemoteData} from '../../remote-data';
import {ViewItem} from '../../view-item';
import Utils from '../../utils/utils';

export type SearchFunction = (query: string) => Observable<RemoteData>;
export type SearchInput = SearchFunction | ViewItem[];

@Component({
    selector: 'app-better-select',
    templateUrl: './better-select.component.html',
    styleUrls: ['./better-select.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BetterSelectComponent),
            multi: true
        }
    ]
})
export class BetterSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {

    randomId = Utils.generateRandomString(8);

    @ViewChild('selectElement', {static: true})
    inputElement: ElementRef;

    private selectElement$: JQuery<HTMLSelectElement>;

    @Input()
    search: SearchInput;

    @Input()
    placeholder = '';

    @Input()
    allowClear = false;

    @Input()
    multiple = true;

    @Input()
    executeDestroy = true;

    @Input()
    minimumInputLength = 1;

    @Input()
    delay = 250;

    static fill(ids: any[], access: ViewItem[]): ViewItem[] {
        return ids
            .map(it => access.find(item => item.id === it))
            .filter(it => isDefined(it) && isDefined(it.id));
    }

    static extract(items: ViewItem[]): string[] {
        return items.map(it => it.id);
    }

    /**
     * @description
     * The registered callback function called when a change event occurs on the input element.
     */
    onChange = (_: any) => {
    }

    /**
     * @description
     * The registered callback function called when a blur event occurs on the input element.
     */
    onTouched = () => {
    }

    constructor() {
    }

    ngOnInit() {
        // allowClear and multiple cannot be enabled together due to bug: https://github.com/select2/select2/issues/3335
        // 'When using allowClear in multiple select, backspace removes all selections'
        if (this.allowClear && this.multiple) {
            this.allowClear = false;
        }
        const isSync = Array.isArray(this.search);

        let opts = {
            minimumInputLength: this.minimumInputLength,
            templateResult: (item) => {
                const imageUrl = item.imageUrl ? item.imageUrl : $(item.element).data('imageUrl');
                const text = escapeHtml(item.text);
                if (imageUrl) {
                    return $(`<span><img src="${imageUrl}"
                            style="vertical-align: middle; width: 16px; height: 16px;" /> ${text}</span>`);
                } else {
                    return $(`<span style="vertical-align: middle; width: 16px; height: 16px;"/> ${text}</span>`);
                }
            },
            templateSelection: (item) => {
                const imageUrl = item.imageUrl ? item.imageUrl : $(item.element).data('imageUrl');
                const text = escapeHtml(item.text);
                if (imageUrl) {
                    return $(`<span><img src="${imageUrl}"
                            style="vertical-align: middle; width: 16px; height: 16px;" /> ${text}</span>`);
                } else {
                    return $(`<span style="vertical-align: middle; width: 16px; height: 16px;" /> ${text}</span>`);
                }
            },
            placeholder: this.placeholder,
            allowClear: this.allowClear,
            multiple: this.multiple
        };

        if (isSync) {
            opts = Object.assign(opts, {
                data: this.search
            });
        } else {
            opts = Object.assign(opts, {
                ajax: {
                    delay: this.delay,
                    transport: (params, success, failure) => {
                        this.getSearch(params.data.term)
                            .pipe(catchError(failure))
                            .subscribe(
                                success
                            );

                        return {};
                    }
                }
            });
        }

        this.selectElement$ = $(this.inputElement.nativeElement);

        if (this.placeholder) {
            // Placeholder in opts is not working
            this.selectElement$.attr('data-placeholder', this.placeholder);
        }

        this.selectElement$.select2(opts);

        this.selectElement$.on('change', (e: any) => {
            const selectedData = this.selectElement$.select2('data');
            const newValue = this.multiple ? (selectedData || []) : (selectedData || [null])[0];
            this.onChange(newValue);
        });

        this.selectElement$.on('blur', (e: any) => {
            this.onTouched();
        });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.selectElement$.prop('disabled', 'disabled');
        } else {
            this.selectElement$.removeProp('disabled');
        }
    }

    writeValue(obj: any): void {
        if (!Array.isArray(obj)) {
            obj = [obj];
        }
        const viewItems = (obj as ViewItem[]).filter(it => isDefined(it) && isDefined(it.id));
        const isSync = Array.isArray(this.search);

        if (isSync) {
            const existingOptions: HTMLOptionElement[] =
                (this.selectElement$.children('option') as any as JQuery<HTMLOptionElement>).toArray();

            const options = viewItems
                // Select items not available in existing Options
                .filter(it => !existingOptions.some(option => option.value === it.id?.toString()))
                // Create Options from them
                .map(it => {
                    const option = new Option(it.text, it.id, true, true);
                    $(option).data('imageUrl', it.imageUrl);
                    return option;
                });

            this.selectElement$
                .append(options)
                .val(viewItems.map(it => it.id))
                .trigger('change');
        } else {
            if (!this.selectElement$?.children) {
                return;
            }
            this.selectElement$.children('option').remove();

            const options = viewItems.map(it => {
                const option = new Option(it.text, it.id, true, true);
                $(option).data('imageUrl', it.imageUrl);
                return option;
            });

            this.selectElement$
                .val(null)
                .append(options)
                .val(viewItems.map(it => it.id))
                .trigger('change');
        }
    }

    getSearch(term: string): Observable<RemoteData> {
        if (typeof this.search === 'function') {
            return this.search(term) as Observable<RemoteData>;
        }

        if (isObservable(this.search)) {
            return this.search as any as Observable<RemoteData>;
        }
        throw {error: '[Better Select] search can be only SearchInput type'};
    }

    ngOnDestroy(): void {
        if (this.executeDestroy && this.selectElement$) {
            this.selectElement$.select2('destroy');
            this.selectElement$ = null;
        }
    }
}

function isDefined(val: any) {
    return val !== undefined && val !== null;
}

function escapeHtml(unsafe: string) {
    return Utils.escapeHtml(unsafe);
}
