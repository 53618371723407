import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {Observable, of, throwError, zip} from 'rxjs';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {JiraService} from '../jira.service';
import {finalize, map, switchMap, tap} from 'rxjs/operators';
import {AppService} from '../app.service';
import {ApiKeyViewService} from './api-key-view.service';
import Utils from '../utils/utils';
import {ApiKeyConfigView} from '../base/apikey.config';
import {LoaderService} from '../loader.service';
import {ConfigEnabled} from './config-enabled';
import CustomValidators from '../utils/custom-validators';
import {CustomData} from './data';

interface ApiKeyView {
    data: CustomData;
    schema: ApiKeyConfigView;
}

const initDataOptionDisabled: ConfigEnabled = {
    general: true,
    dataOptions: {
        get: {off: true, on: true},
        list: {off: true, on: true},
        create: {off: true, on: true},
        update: {off: true, on: true},
        delete: {off: true, on: true},
    },
};

@Component({
    selector: 'app-api-key-form',
    templateUrl: './api-key-form.component.html',
    styleUrls: ['./api-key-form.component.css']
})
export class ApiKeyFormComponent implements OnInit {
    private refreshEventName = 'config-update';
    config$: Observable<ApiKeyView>;
    enabled = initDataOptionDisabled;
    editable = false;

    longApiKey;
    shortApiKey;

    creationTime;
    lastModificationTime;

    form: FormGroup = this.fb.group({
        keyName: ['', [Validators.required, Validators.maxLength(40)]],
        description: [''],
        allowedIPs: ['', [CustomValidators.validateIP()]],

        notValidBeforeDate: [],
        notValidAfterDate: [],

        enabled: [true],

        get: ['off'],
        list: ['off'],
        create: ['off'],
        update: ['off'],
        delete: ['off'],
    });

    constructor(private fb: FormBuilder,
                private app: AppService,
                private aks: ApiKeyViewService,
                private loader: LoaderService,
                private jira: JiraService,
                @Inject(LOCALE_ID) private locale: string) {
    }

    ngOnInit(): void {
        this.config$ = Utils.require(this.jira.getCustomData<CustomData>(),
            'ApiKeyFormComponent', 'ngOnInit', 'this.jira.getCustomData<CustomData>()')
            .pipe(
                switchMap(data => zip(
                    of(data),
                    Utils.require(this.createOrLoad(data),
                        'ApiKeyFormComponent', 'ngOnInit', `this.createOrLoad(${JSON.stringify(data)})`)
                )),
                map(([data, schema]) => {
                    return {data, schema};
                }),
                tap((x: ApiKeyView) => {
                    const view = x.schema;
                    const config = view.config;

                    this.longApiKey = config.key;
                    this.shortApiKey = this.longApiKey.substr(0, 3) + '****' + this.longApiKey.substr(40, 3);

                    this.creationTime = config.creationTime;
                    this.lastModificationTime = config.lastModificationTime;

                    const formValue: any = {
                        id: ('id' in view) ? view.id : '',
                        keyName: config.keyName,
                        description: config.keyDescription,
                        allowedIPs: config.allowedIPs,

                        get: config.get ? 'on' : 'off',
                        list: config.list ? 'on' : 'off',
                        create: config.create ? 'on' : 'off',
                        update: config.update ? 'on' : 'off',
                        delete: config.delete ? 'on' : 'off',

                        notValidBeforeDate: config.notValidBeforeDate,
                        notValidAfterDate: config.notValidAfterDate
                    };

                    this.form.get('enabled').valueChanges.subscribe(it => {
                        this.aks.emitChange(it);
                    });

                    this.form.patchValue(formValue);
                    this.editable = x.data.editable;

                    if (!x.data.editable) {
                        this.disableInputs();
                    }
                })
            );
    }

    private getConfig(x: ApiKeyView): ApiKeyConfigView {
        const v = this.form.getRawValue();
        return {
            id: x.schema.id,
            config: {
                keyName: v.keyName,
                keyDescription: v.description,
                allowedIPs: v.allowedIPs,
                get: v.get === 'on',
                list: v.list === 'on',
                create: v.create === 'on',
                update: v.update === 'on',
                delete: v.delete === 'on',
                notValidBeforeDate: this.toEpoch(v.notValidBeforeDate),
                notValidAfterDate: this.toEpoch(v.notValidAfterDate),
                timeZoneOffset: new Date().getTimezoneOffset()
            }
        };
    }

    saveAndExit(x: ApiKeyView) {
        this.update(x).subscribe(() => {
            this.jira.emitEvent(this.refreshEventName, JSON.stringify(x.schema));
            this.close(x);
        });
    }

    update(x: ApiKeyView) {
        if (this.isOptionsFormValid()) {
            const config = this.getConfig(x);
            return this.app.updateApiKeyConfig(config);
        }
        return of(undefined);
    }

    close(x: ApiKeyView) {
        this.jira.closeDialog(x.schema);
    }

    shouldDisplayError(control: AbstractControl): boolean {
        return control.invalid && (control.dirty || control.touched);
    }

    createOrLoad(customData: CustomData): Observable<ApiKeyConfigView> {
        customData = customData || {};
        if (customData.isNew) {
            return this.app.createApiKeyConfig().pipe(tap((scheme) => {
                this.jira.emitEvent(this.refreshEventName, JSON.stringify(scheme));
            }));
        }
        if (customData.id) {
            return this.app.getApiKeyConfig(customData.id);
        }
        return throwError('Failed to handle init data in project config form ' + customData);
    }

    confirmDeletion(x: ApiKeyView) {
        this.jira.showDialog({
                key: 'confirm-delete',
                customData: {
                    type: 'archive',
                    title: 'Archive API Key?'
                }
            }, (result) => {
                if (result.deleted) {
                    this.loader.fullscreen(true);

                    const view = x.schema;
                    this.app.deleteApiKeyConfig(view.id)
                        .pipe(finalize(() => this.loader.fullscreen(false)))
                        .subscribe(() => {
                            this.jira.emitEvent(this.refreshEventName);
                            this.close(x);
                        });
                }
            }
        );
    }

    reveal() {
        this.shortApiKey = this.longApiKey;
    }

    download(config: ApiKeyView) {
        const blob = new Blob([config.schema.config.key], {type: 'key'});
        const a = document.createElement('a');
        a.download = config.schema.config.keyName + '.key';
        a.href = URL.createObjectURL(blob);
        a.addEventListener('click', (e) => {
            setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
        });
        a.click();
    }

    parseDate(date: string, time: string): number {
        if (date === '') {
            return 0;
        }
        if (time !== '') {
            date = date + 'T' + time;
        }
        return Date.parse(date);
    }

    private toEpoch(date) {
        if (date) {
            return new Date(date).getTime();
        }
        return date;
    }

    private isOptionsFormValid(): boolean {
        Utils.validateAllFormFields(this.form);
        return this.form.valid;
    }

    private disableInputs() {
        this.form.get('keyName').disable();
        this.form.get('allowedIPs').disable();
        this.form.get('description').disable();
    }
}
