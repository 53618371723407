import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Link} from '../../base/link';
import {ViewItem} from '../../view-item';

interface ParentRestrictions {
    namedShareLinkEnabled: boolean;
    showComments: boolean;
    allowAddComment: boolean;
    showAttachments: boolean;
    allowAddAttachment: boolean;
    showLinkedIssues: boolean;
    shareLinkedIssuesContent: boolean;
    showSubtasks: boolean;
    shareSubtasksContent: boolean;
    customFieldsShow: boolean;
    showInternalComments: boolean;
    allowAddInternalComment: boolean;
    allowAddPublicAttachment: boolean;
    allowWorkflowTransition: boolean;
    showWorklog: boolean;
    showWebLinks: boolean;
    showChangelog: boolean;
    showApproval: boolean;
    showSmartChecklist: boolean;
    showIssueChecklist: boolean;
    allowEditSummary: boolean;
    allowEditDescription: boolean;
    allowCreateNewIssue: boolean;
    allowEditPriority: boolean;
    samlLoginDisabled: boolean;
    samlLoginRequired: boolean;
    allowCommentsWithRestrictions: boolean;
}

interface RestrictionCheckParams {
    parentRestrictionsField: keyof ParentRestrictions;
    formFieldName: string;
    value: any;
    message?: string;
}

@Injectable({
    providedIn: 'root'
})
export class ParentRestrictionsService {
    parentRestrictions: ParentRestrictions;

    commentsRestrictionInfo: string;
    internalCommentsRestrictionInfo: string;
    attachmentsRestrictionInfo: string;
    publicAttachmentsRestrictionInfo: string;
    linkedIssuesRestrictionInfo: string;
    subtasksRestrictionInfo: string;
    statusRestrictionInfo: string;
    priorityRestrictionInfo: string;
    worklogRestrictionInfo: string;
    changelogRestrictionInfo: string;
    approvalRestrictionInfo: string;
    summaryRestrictionInfo: string;
    descriptionRestrictionInfo: string;
    samlRestrictionInfo: string;
    weblinksRestrictionInfo: string;
    allowCreateNewIssueRestrictionInfo: string;
    smartChecklistRestrictionInfo: string;
    issueChecklistRestrictionInfo: string;

    init(link: Link) {
        const p = link.parent;
        const i = p.issueFieldConfig;

        this.parentRestrictions = {
            namedShareLinkEnabled: !!i.namedShareLinkEnabled,
            showComments: !!i.commentsShow,
            allowAddComment: !!i.commentsAllowAdd,
            showAttachments: !!i.attachmentsShow,
            allowAddAttachment: !!i.attachmentsAllowAdd,
            showLinkedIssues: !!i.linkedIssuesShow,
            shareLinkedIssuesContent: !!i.linkedIssuesContentShow,
            showSubtasks: !!i.subtasksShow,
            shareSubtasksContent: !!i.subtasksContentShow,
            customFieldsShow: !!i.customFieldsShow,
            showInternalComments: !!i.commentsInternalShow,
            allowAddInternalComment: !!i.commentsInternalAllowAdd,
            allowAddPublicAttachment: !!i.attachmentsPublicAllowAdd,
            allowWorkflowTransition: !!i.statusTransitionOn,
            showWorklog: !!i.showWorklog,
            showWebLinks: !!i.showWebLinks,
            showChangelog: !!i.showChangelog,
            showApproval: !!i.showApproval,
            showSmartChecklist: !!i.showSmartChecklist,
            showIssueChecklist: !!i.showIssueChecklist,
            allowEditSummary: !!i.summaryAllowEdit,
            allowEditDescription: !!i.descriptionAllowEdit,
            allowCreateNewIssue: !!i.newIssueAllowCreate,
            allowEditPriority: !!i.priorityAllowEdit,
            samlLoginDisabled: p.securityConfig.samlLogin !== 'DISABLED',
            samlLoginRequired: p.securityConfig.samlLogin !== 'REQUIRED',
            allowCommentsWithRestrictions: !!p.securityConfig.displayCommentsWithRestrictions,
        };
    }

    calculateRestrictionsInfo(form: FormGroup): void {
        if (!this.parentRestrictions) {
            throw new Error('Parent restrictions were not properly initialized! Use init method first');
        }
        this.commentsRestrictionInfo = this.getValidationMessage(form, [
            { parentRestrictionsField: 'showComments', formFieldName: 'comments', value: 'View' },
            { parentRestrictionsField: 'allowAddComment', formFieldName: 'comments', value: 'Add' }
        ]);

        this.internalCommentsRestrictionInfo = this.getValidationMessage(form, [
            { parentRestrictionsField: 'showInternalComments', formFieldName: 'internalComments', value: 'View' },
            { parentRestrictionsField: 'allowAddInternalComment', formFieldName: 'internalComments', value: 'Add' }
        ]);

        this.attachmentsRestrictionInfo = this.getValidationMessage(form, [
            { parentRestrictionsField: 'showAttachments', formFieldName: 'attachments', value: 'View' },
            { parentRestrictionsField: 'allowAddAttachment', formFieldName: 'attachments', value: 'Add' }
        ]);

        this.publicAttachmentsRestrictionInfo = this.getValidationMessage(form, [
            { parentRestrictionsField: 'allowAddPublicAttachment', formFieldName: 'publicAttachments', value: 'View' }
        ]);

        this.linkedIssuesRestrictionInfo = this.getValidationMessage(form, [
            { parentRestrictionsField: 'showLinkedIssues', formFieldName: 'linkedIssues', value: 'View' },
            { parentRestrictionsField: 'shareLinkedIssuesContent', formFieldName: 'linkedIssues', value: 'ViewAll' }
        ]);

        this.subtasksRestrictionInfo = this.getValidationMessage(form, [
            { parentRestrictionsField: 'showSubtasks', formFieldName: 'subtasks', value: 'View' },
            { parentRestrictionsField: 'shareSubtasksContent', formFieldName: 'subtasks', value: 'ViewAll' }
        ]);

        this.statusRestrictionInfo = this.getValidationMessage(form, [
            { parentRestrictionsField: 'allowWorkflowTransition', formFieldName: 'status', value: 'Edit' }
        ]);

        this.priorityRestrictionInfo = this.getValidationMessage(form, [
            { parentRestrictionsField: 'allowEditPriority', formFieldName: 'priority', value: 'Edit' }
        ]);

        this.worklogRestrictionInfo = this.getValidationMessage(form, [
            { parentRestrictionsField: 'showWorklog', formFieldName: 'worklog', value: 'View' }
        ]);

        this.changelogRestrictionInfo = this.getValidationMessage(form, [
            { parentRestrictionsField: 'showChangelog', formFieldName: 'changelog', value: 'View' }
        ]);

        this.approvalRestrictionInfo = this.getValidationMessage(form, [
            { parentRestrictionsField: 'showApproval', formFieldName: 'approval', value: 'View' }
        ]);

        this.summaryRestrictionInfo = this.getValidationMessage(form, [
            { parentRestrictionsField: 'allowEditSummary', formFieldName: 'summary', value: 'Edit' }
        ]);

        this.descriptionRestrictionInfo = this.getValidationMessage(form, [
            { parentRestrictionsField: 'allowEditDescription', formFieldName: 'descriptionOpts', value: 'Edit' }
        ]);

        this.allowCreateNewIssueRestrictionInfo = this.getValidationMessage(form, [
            { parentRestrictionsField: 'allowCreateNewIssue', formFieldName: 'issueCreationOpts', value: 'Allow' }
        ]);

        this.samlRestrictionInfo = this.getValidationMessage(form, [
            { parentRestrictionsField: 'samlLoginRequired', formFieldName: 'samlLoginRequired',
                value: false, message: 'Corporate SSO is required by parent configuration.' },
            { parentRestrictionsField: 'samlLoginDisabled', formFieldName: 'samlLoginRequired',
                value: true, message: 'Corporate SSO is disabled by parent configuration.' }
        ]);

        this.smartChecklistRestrictionInfo = this.getValidationMessage(form, [
            { parentRestrictionsField: 'showSmartChecklist', formFieldName: 'showSmartChecklist', value: true,
            message: 'Smart Checklist is disabled by parent configuration' }
        ]);

        this.issueChecklistRestrictionInfo = this.getValidationMessage(form, [
            { parentRestrictionsField: 'showIssueChecklist', formFieldName: 'showIssueChecklist', value: true,
                message: 'Issue Checklist is disabled by parent configuration' }
        ]);

        this.weblinksRestrictionInfo = this.getValidationMessage(form, [
            { parentRestrictionsField: 'showWebLinks', formFieldName: 'weblinks', value: 'View' }
        ]);
    }

    private getValidationMessage(form: FormGroup, params: RestrictionCheckParams[]): string {
        const infoLines: string[] = [];
        params.forEach(({ parentRestrictionsField, formFieldName, value, message }) => {
            if (!this.parentRestrictions[parentRestrictionsField]) {
                const formControlValue = form.get(formFieldName)?.value;
                if (Array.isArray(formControlValue)) {
                    const matchingItem = formControlValue.find((item: ViewItem) => item.id === value);
                    if (matchingItem) {
                        const info = message ? message : `Permission "${matchingItem.text}" is restricted by parent configuration.`;
                        infoLines.push(info);
                    }
                } else if (formControlValue === value) {
                    const info = message ? message : `This field is restricted by parent configuration.`;
                    infoLines.push(info);
                }
            }
        });
        return infoLines.join('\n');
    }
}
