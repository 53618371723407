import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-share-editor-action-panel',
    templateUrl: './share-editor-action-panel.component.html',
    styleUrls: ['./share-editor-action-panel.component.css']
})
export class ShareEditorActionPanelComponent implements OnChanges, OnInit {
    @Input() initialSelectedUsers: { email: string, type: string }[];
    @Input() currentSelectedUserEmails: string;
    @Input() initialMailTo: string;

    isHidden = true;
    initialSelectedUserEmails: string[] = [];
    currentMailTo: string;
    newlyAddedUsers: string[] = [];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.currentSelectedUserEmails) {
            this.newlyAddedUsers = this.getNewlyAddedUsers();
            this.currentMailTo = this.getNewlyAddedUsersMailTo(this.initialMailTo);
            this.isHidden = this.newlyAddedUsers.length === 0;
        }
    }

    ngOnInit(): void {
        this.initialSelectedUserEmails = this.initialSelectedUsers.map(item => item.email);
        this.isHidden = true;
    }

    hideButtonAndUpdateUsers(): void {
        this.initialSelectedUserEmails = this.currentSelectedUserEmails
            .split(',')
            .map(user => user.trim());

        setTimeout(() => { this.isHidden = true; }, 1500);
    }

    getNewlyAddedUsers(): string[] {
        const currentSelectedUsersArray = this.currentSelectedUserEmails
            .split(',')
            .map(user => user.trim());

        return currentSelectedUsersArray.filter(
            user => !this.initialSelectedUserEmails.includes(user) && this.isValidEmail(user)
        );
    }

    private isValidEmail(email: string): boolean {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email.trim());
    }

    getNewlyAddedUsersMailTo(mailTo: string): string {
        if (this.newlyAddedUsers.length === 0) {
            return mailTo || '';
        }
        const updatedEmails = `mailto:${this.newlyAddedUsers.join(', ')}`;
        return mailTo ? mailTo.replace(/mailto:[^?]*/, updatedEmails) : updatedEmails;
    }
}
