import {Component, OnInit} from '@angular/core';
import {forkJoin, merge, Observable, of, zip} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {ServerDataSource} from '../../components/app-table.component';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {ShareActionService} from '../../share-action.service';
import {JiraService, Project} from '../../jira.service';
import {map, switchMap, tap} from 'rxjs/operators';
import Utils from '../../utils/utils';
import {Link, LinkFilterCriteria} from '../../base/link';
import {ItemService} from '../../base/item.service';
import {Column} from '../../components/customize-columns/customize-columns.component';
import {AppService} from '../../app.service';
import {CsvData, ExportCsv} from '../../components/exportCsv/exportCsv';
import {AtlassianUser} from '../../atlassian-user';
import {LoaderService} from '../../loader.service';
import {DatePipe} from '@angular/common';

interface ProjectListAppState {
    project: Project;
}

@Component({
    selector: 'app-share-list',
    templateUrl: './share-list.component.html',
    styleUrls: ['./share-list.component.css'],
})
export class ShareListComponent implements OnInit {

    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private app: AppService,
                private shareActions: ShareActionService,
                private item: ItemService,
                private jira: JiraService,
                private loader: LoaderService,
                private datePipe: DatePipe) {
    }

    features = window.getToken().features;
    config$: Observable<ProjectListAppState>;

    filterForm = new FormGroup({
        textSearch: new FormControl('status: active '),
    });

    deleteAll = false;
    source = new ServerDataSource<Link, LinkFilterCriteria>(this.http);
    moreThanOneItemIsSelected = false;
    showFilterInformation = false;
    projectId;

    columnForm = new FormGroup({
        columns: new FormControl(''),
    });

    private baseInstanceUrl: string;
    displayErrorCsvMessage = false;
    columns = Column.create(
        ['share-type', 'Share type', true],
        ['issue-key', 'Issue key', true],
        ['link-name', 'Link name', true],
        ['created-by', 'Created by', true],
        ['created', 'Created', true],
        ['last-updated-by', 'Last updated by', true],
        ['last-updated', 'Last updated', true],
        ['opened', 'Opened', true],
        ['password', 'Password', true],
        ['expiration', 'Expiration', true],
        ['link-status', 'Link status', true],
        ['selected-users-emails', 'Emails', false],
        ['selected-users-domains', 'Domains', false],
        ['options', 'Options', true],
        ['actions', 'Actions', true],
    );

    header: string[] = [
        'Share type', 'Project ID', 'Issue key',
        'Issue ID', 'Issue', 'Share UUID', 'Link name', 'Share link url',
        'Created by', 'Created', 'Last updated by',
        'Last updated', 'Opened', 'Password', 'Expiration',
        'Link status', 'Emails', 'Domains',
    ];

    ngOnInit() {
        const refresh = this.jira.observeEvent('share-update');
        refresh.subscribe(() => {
            this.source.refresh();
        });

        this.jira.getBaseUrl().subscribe(value => {
            this.baseInstanceUrl = value;
        });

        this.config$ = merge(refresh, of(undefined)).pipe(
            switchMap(() => {
                return zip(
                    Utils.require(Utils.combineParams(this.route.parent),
                        'ShareListComponent', 'ngOnInit', 'Utils.combineParams(this.route.parent)'),
                );
            }),
            switchMap(([params]) => zip(
                Utils.require(this.project(params),
                    'ShareListComponent', 'ngOnInit', `this.project(${JSON.stringify(params)})`),
            )),
            map(([project]) => ({project})),
            tap(conf => {
                const project = conf.project;
                this.filterForm.get('textSearch').setValue('status: active ');
                this.projectId = project.id;
                this.source.reload({
                    endPoint: `/api/share`,
                    defaultSort: 'created',
                    defaultSortOrder: 'desc',
                    defaultLimit: 20,
                    filter: {projectId: project.id, query: this.filterForm.get('textSearch').value},
                    headers: new HttpHeaders({
                        'X-Operation-Name': 'Fetching shares',
                    }),
                });
            }),
        );
    }

    updateMoreThanOneItemSelected(value: boolean) {
        this.moreThanOneItemIsSelected = value;
    }

    exportCSV(): void {
        this.displayErrorCsvMessage = false;
        const baseUrl = this.baseInstanceUrl;
        const projectId = this.projectId;
        const header: string[] = this.header;
        let searchQuery = this.filterForm.get('textSearch').value;
        searchQuery = searchQuery.split(' ').join('%20');
        this.loader.fullscreen(true);
        getAllSharesArray(this.app).then(sharesArray => {
            if (sharesArray.length === 0) {
                this.loader.fullscreen(false);
                this.displayErrorCsvMessage = true;
                return false;
            }
            of(sharesArray).pipe(
                switchMap((array) => {
                    const users$: Observable<AtlassianUser>[] = [];
                    const usersIds = new Set(array.map(el => el.createdBy)
                        .concat(array.filter(id => id.updatedBy !== null)
                            .map(el => el.updatedBy)));

                    usersIds.forEach(userId => {
                        const user$: Observable<AtlassianUser> = this.jira.requestUser(userId);
                        users$.push(user$);
                    });

                    return zip(of(array), forkJoin(users$));
                }),
                map(([array, user]) => {
                    const users: Map<string, string> = new Map(user.map(i => [i.accountId, i.displayName]));
                    const modifiedArray: Link[] = array.map(el => {
                        el.createdBy = users.get(el.createdBy);
                        el.updatedBy = users.get(el.updatedBy);
                        return el;
                    });
                    return modifiedArray;
                }),
            ).subscribe((a) => {
                const rows = getRows(a);
                const csvData: CsvData = {rows, header};
                ExportCsv.exportToCsv('project_' + projectId + '_external_share', csvData);
                this.loader.fullscreen(false);
            });
        });

        function getAllSharesArray(app: AppService): Promise<Array<Link>> {
            return new Promise(resolve => {
                const query = {projectId, userQuery: searchQuery};
                app.getAllShares(query).then(elements => {
                    let sharesArray: Array<Link> = [];
                    for (const array of elements) {
                        sharesArray = sharesArray.concat(array);
                    }
                    resolve(sharesArray);
                });
            });
        }

        const datePipe = this.datePipe;

        function getRows(sharesArray: Link[]): string[][] {
            const rows: string[][] = [];
            for (const data of sharesArray) {
                const row: string[] = [
                    data.type,
                    data.projectId,
                    data.issueKey,
                    data.issueId,
                    data.issueKey ? baseUrl + '/browse/' + data.issueKey : '',
                    data.uuid,
                    data.name,
                    data.url,
                    data.createdBy,
                    Utils.toIsoDate(data.created, datePipe),
                    data.updatedBy,
                    Utils.toIsoDate(data.updated, datePipe),
                    data.opened.toString(),
                    data.password,
                    Utils.toIsoDate(data.expiration, datePipe),
                    data.status.statusType,
                    data.selectedUsersConfig ? data.selectedUsersConfig.emails : '',
                    data.selectedUsersConfig ? data.selectedUsersConfig.domains : '',
                ];
                rows.push(row);
            }
            return rows;
        }
    }

    project(params: ParamMap): Observable<Project> {
        return this.jira.getProject(params.get('projectId'));
    }

    doDeleteAll(elements: any[]) {
        const toDelete = elements.filter(it => it._delete) as Link[];
        this.shareActions.deleteMultiple(toDelete);
    }

    doEditAll(elements: any[], query: string, projectId: string) {
        const toEdit = elements.filter(it => it._delete) as Link[];
        this.shareActions.bulkEdit(toEdit, {query, projectId});
    }

    filter(event: KeyboardEvent | MouseEvent, projectId) {
        if ('code' in event) {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                const query: string = this.filterForm.value.textSearch;
                this.source.updateFilter({projectId, query});
            }
        } else {
            const query: string = this.filterForm.value.textSearch;
            this.source.updateFilter({projectId, query});
        }
    }

    createJql(config: ProjectListAppState) {
        this.item.createJqlFilter(config.project.id)
            .subscribe(link => {
                this.jira.emitEvent('share-update', JSON.stringify(link));
                this.jira.showDialog({
                        key: 'link-edition-popup',
                        customData: {
                            uuid: link.uuid,
                            isNew: true,
                        },
                    },
                );
            });
    }

    createNewBoard(config: ProjectListAppState) {
        this.item.createShare({
            projectId: config.project.id,
            type: 'BOARD',
        }).subscribe(link => {
            this.jira.emitEvent('share-update', JSON.stringify(link));
            this.jira.showDialog({
                    key: 'link-edition-popup',
                    customData: {
                        uuid: link.uuid,
                        isNew: true,
                    },
                },
            );
        });
    }
}
