import {NgModule} from '@angular/core';
import {BaseModule} from '../base/base.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DeleteConfirmationComponent} from '../delete-confirmation/delete-confirmation.component';
import {IconsModule} from '../icons/icons.module';
import {TitleCasePipe} from '@angular/common';
import {ShareFormParentComponent} from './share-form-parent.component';
import {RouterModule} from '@angular/router';
import {EmailFormComponent} from './email-form/email-form.component';
import {EmailFormModule} from '../email-form/email-form.module';
import {ProjectSchemaFormModule} from '../project-schema-form/project-schema-form.module';
import {
    ShareFormFilterFieldsComponent
} from '../components/share-form-filter-fields/share-form-filter-fields.component';
import {SaveIndicatorComponent} from './save-indicator/save-indicator.component';
import {CustomBoardConfigComponent} from './share-form-components/custom-board/custom-board-config.component';
import {ShareEditorTabComponent} from './share-form-components/share-editor-tab/share-editor-tab.component';
import {
    ShareEditorTextFieldComponent
} from './share-form-components/share-editor-text-field/share-editor-text-field.component';
import {
    ShareEditorTextAreaComponent
} from './share-form-components/share-editor-text-area/share-editor-text-area.component';
import {
    ShareEditorCheckboxComponent
} from './share-form-components/share-editor-checkbox/share-editor-checkbox.component';
import {
    ShareEditorTabSubcategoryComponent
} from './share-form-components/share-editor-tab-category-sub/share-editor-tab-subcategory.component';
import {
    ShareEditorDynamicSelectComponent
} from './share-form-components/share-editor-dynamic-select/share-editor-dynamic-select.component';
import {
    ShareEditorActionPanelComponent
} from './share-form-components/share-editor-action-panel/share-editor-action-panel.component';
import {
    ShareEditorDateFieldComponent
} from './share-form-components/share-editor-date-field/share-editor-date-field.component';
import {ShareEditorFooterComponent} from './share-form-components/share-editor-footer/share-editor-footer.component';
import {ShareFormHeaderComponent} from './share-form-components/share-form-header/share-form-header.component';
import {SharedPagePreviewComponent} from './share-form-components/share-form-preview/shared-page-preview.component';
import {
    ShareFormPreviewShareActivityComponent
} from './share-form-components/share-form-preview-share-activity/share-form-preview-share-activity.component';
import {
    ShareFormPreviewFooterComponent
} from './share-form-components/share-form-preview-footer/share-form-preview-footer.component';
import {
    ShareFormPreviewFooterTabComponent
} from './share-form-components/share-form-preview-footer-tab/share-form-preview-footer-tab.component';
import {JqlVerificationDirective} from './share-form-components/jql-verification.directive';
import {PasswordDirective} from './share-form-components/password.directive';
import {SecretDirective} from './share-form-components/secret.directive';
import {ToggleVisibilityDirective} from './share-form-components/toggle-visibility.directive';
import {ShareFormComponent} from './share-form.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { QuickFiltersListComponent } from './share-form-components/quick-filters-list/quick-filters-list.component';


@NgModule({
    declarations: [
        ShareFormParentComponent,
        ShareFormComponent,
        EmailFormComponent,
        DeleteConfirmationComponent,
        ShareFormFilterFieldsComponent,
        SaveIndicatorComponent,
        CustomBoardConfigComponent,
        ShareEditorTabComponent,
        ShareEditorTextFieldComponent,
        ShareEditorTextAreaComponent,
        ShareEditorCheckboxComponent,
        ShareEditorTabSubcategoryComponent,
        ShareEditorDynamicSelectComponent,
        ShareEditorActionPanelComponent,
        ShareEditorDateFieldComponent,
        ShareEditorFooterComponent,
        ShareFormHeaderComponent,
        SharedPagePreviewComponent,
        ShareFormPreviewShareActivityComponent,
        ShareFormPreviewFooterComponent,
        ShareFormPreviewFooterTabComponent,
        JqlVerificationDirective,
        PasswordDirective,
        SecretDirective,
        ToggleVisibilityDirective,
        QuickFiltersListComponent
    ],
    imports: [
        BaseModule,
        BrowserModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        EmailFormModule,
        IconsModule,
        ProjectSchemaFormModule,
        DragDropModule
    ],
    exports: [
        ShareFormComponent,
        ShareFormParentComponent,
    ],
    providers: [
        TitleCasePipe
    ]
})
export class ShareFormModule {
}
