import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ConfigEnabled} from '../config-enabled';

@Component({
    selector: 'app-data-options',
    templateUrl: './data-options.component.html',
    styleUrls: ['./data-options.component.css']
})
export class DataOptionsComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() enabled: ConfigEnabled;
    @Input() showSmartCheckListVisible: boolean;
    @Input() showIssueChecklistVisible: boolean;
    features = window.getToken().features;

    namedShareLinkEnabled = [];
    comments = [];
    internalComments = [];
    attachments = [];
    publicAttachments = [];
    linkedIssue = [];
    subtask = [];
    statusTransition = [];
    priorityEdit = [];
    worklog = [];
    changelog = [];
    approval = [];
    showSmartCheckList = [];
    showIssueChecklist = [];
    summaryEdit = [];
    descriptionEdit = [];
    issueCreate = [];
    webLinks = [];

    constructor() {
    }

    ngOnInit(): void {
        const opt = this.enabled.dataOptions;
        this.namedShareLinkEnabled = [
            ['off', 'Disable', null, opt.publicShareLinkEnabled.off],
            ['enable', 'Enable', 'Allows public link to be used when accessing the shared link', opt.publicShareLinkEnabled.enable],
        ];

        this.comments = [
            ['off', 'Disable', null, opt.comments.off],
            ['show', 'Allow View', 'All comments will be visible on the shared link', opt.comments.show],
            ['add', 'View & Add', 'Anyone with access to the link can add comments, no Jira account or license required!',
                opt.comments.add],
        ];

        this.internalComments = [
            ['off', 'Disable', null, opt.internalComments.off],
            ['show', 'Allow View', 'All internal comments will be visible on the shared link', opt.internalComments.show],
            ['add', 'View & Add', 'Anyone with access to the link can add internal comments, no Jira account or license required!',
                opt.internalComments.add],
        ];

        this.attachments = [
            ['off', 'Disable', null, opt.attachments.off],
            ['show', 'Allow View', 'All attachments will be visible on the shared link', opt.attachments.show],
            ['add', 'View & Add', 'Anyone with access to the link can add attachments, no Jira account or license required!',
                opt.attachments.add],
        ];

        this.publicAttachments = [
            ['off', 'Disable', null, opt.publicAttachments.off],
            ['add', 'View & Add', 'Anyone with access to the link can add attachments, no Jira account or license required!',
                opt.publicAttachments.add],
        ];

        this.linkedIssue = [
            ['off', 'Disable', null, opt.linkedIssue.off],
            ['show', 'List', 'Overview information about all linked issues will be visible on the shared link', opt.linkedIssue.show],
            ['add', 'Share content', 'Share linked issues content with same rules as this share', opt.linkedIssue.add],
        ];

        this.subtask = [
            ['off', 'Disable', null, opt.subtask.off],
            ['show', 'List', 'Overview information about all sub tasks will be visible on the shared link', opt.subtask.show],
            ['add', 'Share content', 'Share sub-tasks content with same rules as this share', opt.subtask.add],
        ];

        this.statusTransition = [
            ['off', 'Disable', null, opt.statusTransition.off],
            ['on', 'Enable', 'Everyone with shared link will be able to change status of the issue', opt.statusTransition.on],
        ];

        this.priorityEdit = [
            ['off', 'Disable', null, opt.priorityEdit.off],
            ['on', 'Enable', 'Everyone with shared link will be able to change priority of the issue', opt.priorityEdit.on]
        ];

        this.worklog = [
            ['off', 'Disable', null, opt.worklog.off],
            ['on', 'Enable', 'Everyone with shared link will be able to see worklog of the issue', opt.worklog.on],
        ];

        this.webLinks = [
            ['off', 'Disable', null, opt.webLinks.off],
            ['on', 'Enable', 'Everyone with shared link will be able to see web links shared with the issue', opt.webLinks.on],
        ];

        this.changelog = [
            ['off', 'Disable', null, opt.changelog.off],
            ['on', 'Enable', 'All history items from changelog will be visible on the shared link', opt.changelog.on],
        ];

        this.approval = [
            ['off', 'Disable', null, opt.approval.off],
            ['on', 'Enable', 'All approvals will be visible on the shared link', opt.approval.on],
        ];

        this.summaryEdit = [
            ['off', 'Disable', null, opt.summaryEdit.off],
            ['on', 'Enable', 'Everyone with shared link will be able to edit issue summary', opt.summaryEdit.on],
        ];

        this.descriptionEdit = [
            ['off', 'Disable', null, opt.descriptionEdit.off],
            ['on', 'Enable', 'Everyone with shared link will be able to edit issue description', opt.descriptionEdit.on],
        ];

        this.issueCreate = [
            ['off', 'Disable', null, opt.issueCreate.off],
            ['on', 'Enable', 'Everyone with shared link will be able to create new issue in the project', opt.issueCreate.on],
        ];


        if (this.showSmartCheckListVisible) {
            this.showSmartCheckList = [
                ['off', 'Disable', null, opt.showSmartCheckList.off],
                ['on', 'Enable', 'Everyone with shared link will be able to see Smart Checklist items', opt.showSmartCheckList.on],
            ];
        }
        if (this.showIssueChecklistVisible) {
            this.showIssueChecklist = [
                ['off', 'Disable', null, opt.showIssueChecklist.off],
                ['on', 'Enable', 'Everyone with shared link will be able to see Issue Checklist for Jira items', opt.showIssueChecklist.on],
            ];
        }
    }
}
