import {AbstractControl, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {ActivatedRoute, convertToParamMap, ParamMap} from '@angular/router';
import {LITE_APP_NAME} from '../../const';
import {combineLatest, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Constants} from './constants';
import {ElementRef} from '@angular/core';
import {Image} from '../app.service';

export default class Utils {

    public static isEmpty(value) {
        return (!value || 0 === value.length);
    }

    public static toIsoDate(date, datePipe) {
        if (date) {
            return datePipe.transform(date, Constants.ISO_DATE_TIME_FORMAT) + 'Z';
        } else {
            return '';
        }
    }

    public static isDefined(value: any) {
        return value !== null && value !== undefined;
    }

    public static isNotEmptyArray(items) {
        return Array.isArray(items) && items.length > 0;
    }

    public static isEmptyArray(items) {
        return Array.isArray(items) && items.length === 0;
    }

    public static isTrue(val) {
        return val === 'true';
    }

    public static copyToClipboard(password: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = password;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    public static generateRandomString(length: number): string {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#$%^&*()_+';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    public static validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.updateValueAndValidity({onlySelf: true, emitEvent: false});
                control.markAsTouched({onlySelf: true});
                control.markAsDirty({onlySelf: true});
            } else if (control instanceof FormGroup) {
                control.markAsTouched({onlySelf: true});
                control.markAsDirty({onlySelf: true});
                this.validateAllFormFields(control);
            }
        });
    }

    public static isLiteApp(queryParam: ParamMap) {
        return queryParam.get('n') === LITE_APP_NAME;
    }

    public static isNumeric(num) {
        return !isNaN(num);
    }

    public static combineParams(route: ActivatedRoute): Observable<ParamMap> {
        return combineLatest([route.params, route.queryParams]).pipe(
            map(([p1, p2]) => {
                return convertToParamMap({...p1, ...p2});
            })
        );
    }

    public static splitEmails(emailString): string[] {
        if (emailString) {
            const emailWithAngleBracketsRegExp = new RegExp(/<(.*?)>/gi);
            if (emailWithAngleBracketsRegExp.test(emailString)) {
                const emails = [];
                for (const result of emailString.matchAll(emailWithAngleBracketsRegExp)) {
                    emails.push(result[1]);
                }
                return this.arrayWithoutDuplicates(emails);
            } else if (emailString.includes(';')) {
                return this.arrayWithoutDuplicates(emailString.split(';'));
            } else {
                return this.arrayWithoutDuplicates(emailString.split(Constants.COMMA));
            }
        }
        return [];
    }

    public static splitEmailDomains(emailDomainString): string[] {
        if (emailDomainString) {
            return this.arrayWithoutDuplicates(emailDomainString.split(Constants.COMMA));
        }
        return [];
    }

    public static shouldDisplayError(control: AbstractControl): boolean {
        return control.invalid && (control.dirty || control.touched);
    }

    private static arrayWithoutDuplicates(input) {
        return this.trimArrayElements(input)
            .sort()
            .filter((item, index, array) => {
                return item ? !index || item.trim() !== array[index - 1] : false;
            });
    }

    private static trimArrayElements(array) {
        const trimmed = [];
        array.forEach(item => {
            if (item) {
                trimmed.push(item.trim());
            }
        });
        return trimmed;
    }

    public static expandTextArea(el: ElementRef<HTMLInputElement>) {
        if (!!el) {
            el.nativeElement.style.height = el.nativeElement.scrollHeight + 2 + 'px';
        }
    }

    public static prepareLinkFriendlyName(name) {
        return name.replace(/[?\s=+&%#|^{}<>;"'`\/[\]\\]/g, '_')
            .toLowerCase();
    }

    public static fileToModel(file: File): Observable<Image> {
        if (!file) {
            return of(null);
        }
        return new Observable(subscriber => {
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = (event) => {
                subscriber.next({mime: file.type, base64: btoa(event.target.result.toString())});
                subscriber.complete();
            };
        });
    }

    public static fileToString(file: File): Observable<string> {
        if (!file) {
            return of(null);
        }
        return new Observable(subscriber => {
            const reader = new FileReader();
            reader.readAsText(file);
            reader.onload = (event) => {
                subscriber.next(event.target.result.toString());
                subscriber.complete();
            };
        });
    }

    public static modelToFile(image: Image): File {
        if (!image || !image.base64 || !image.mime) {
            return null;
        }
        const bs = atob(image.base64);
        const buffer = new ArrayBuffer(bs.length);
        const ba = new Uint8Array(buffer);
        for (let i = 0; i < bs.length; i++) {
            ba[i] = bs.charCodeAt(i);
        }
        const blob = new Blob([ba], {type: image.mime});
        return new File([blob], 'image', {type: image.mime});
    }

    public static conditionalValidator(predicate: () => boolean, validator: ValidatorFn): ValidatorFn {
        return control => {
            if (predicate()) {
                return validator(control);
            }
            return null;
        };
    }

    public static updateWarnMessage(control: AbstractControl,
                                    warnMessages: { [key: string]: string } | { [key: string]: string }[]): string {
        if (!control || !control.errors) {
            return '';
        }

        const warnMessagesArray = Array.isArray(warnMessages) ? warnMessages : [warnMessages];

        for (const errorKey of Object.keys(control.errors)) {
            const errorDetail = control.errors[errorKey];

            for (const warnMessage of warnMessagesArray) {
                if (warnMessage && warnMessage[errorKey]) {
                    return warnMessage[errorKey];
                }
            }

            if (typeof errorDetail === 'string') {
                return 'Warning: ' + errorDetail;
            }
        }

        return '';
    }

    public static escapeHtml(unsafe: string) {
        return unsafe
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#039;');
    }

    public static require<T>(obs: Observable<T>,
                             component: string,
                             source: string,
                             msg: string): Observable<T> {
        if (obs === undefined) {
            const error = new Error(`Observable is undefined`);
            this.reportError(error, component, source, msg);
        }

        return obs;
    }

    private static reportError(error: Error, component: string, source: string, msg: string, colno: number = 0, lineno: number = 0) {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/api/report-error', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify({
            event: 'Observable error detected',
            component,
            source,
            lineno,
            colno,
            msg,
            stack: error.stack.toString(),
            message: error.message.toString(),
            url: window.location.toString()
        }));
    }
}
