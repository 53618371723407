import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {map, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {JiraService} from '../../jira.service';
import Utils from '../../utils/utils';
import {ActivityParameters} from '../../components/activity/activity.component';
import {ItemService} from '../../base/item.service';

export interface ListAppState {
    issueId: string;
    projectId: string;
}

@Component({
    selector: 'app-project-view',
    templateUrl: './issue-view.component.html',
    styleUrls: ['./issue-view.component.css']
})
export class IssueViewComponent implements OnInit {
    config$: Observable<ListAppState>;

    tab: string;
    createDisabled = false;

    constructor(private route: ActivatedRoute,
                private item: ItemService,
                private jira: JiraService) {
    }

    ngOnInit() {
        this.jira.resize('es-expand');

        this.config$ = Utils.combineParams(this.route).pipe(
            switchMap((params) => this.jira.getIssueCached(params.get('issueId'))),
            map((issue) => {
                this.tab = 'links';
                return Object.assign({}, {
                    issueId: issue.id,
                    projectId: issue.fields.project.id
                });
            })
        );
    }

    createNew(config: ListAppState) {
        this.createDisabled = true;

        this.item.create(config.issueId, config.projectId).subscribe(link => {
            this.jira.emitEvent('share-update', JSON.stringify(link));
            this.jira.showDialog({
                    key: 'link-edition-popup',
                    customData: {uuid: link.uuid}
                }
            );
            this.createDisabled = false;
        });
    }

    changeTab(tab: string) {
        this.tab = tab;
    }

    getActivityParameters(config: ListAppState): ActivityParameters {
        return {
            level: 'ISSUE',
            projectId: config.projectId,
            issueId: config.issueId
        };
    }
}
