import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {JiraService, Project, ProjectSearchResponse} from '../jira.service';
import {ItemService} from '../base/item.service';
import {SearchFunction} from '../components/better-select/better-select.component';
import {finalize, map} from 'rxjs/operators';
import Utils from '../utils/utils';
import {CustomData} from './data';
import {of} from 'rxjs';

@Component({
    selector: 'app-create-share',
    templateUrl: './create-share.component.html',
    styleUrls: ['./create-share.component.css']
})
export class CreateShareComponent implements OnInit {
    form: FormGroup;
    customData: CustomData;
    issuePicker: SearchFunction;
    projectPicker: SearchFunction;
    isCreateButtonDisabled = false;

    constructor(private fb: FormBuilder,
                private jira: JiraService,
                private item: ItemService) {
        this.form = this.fb.group({
            type: ['', [Validators.required]],
            projectId: [''],
            issueId: [''],
            issuePickerValue: [''],
            projectPickerValue: ['']
        });
        this.issuePicker = this.issuePickerFoo.bind(this);
        this.projectPicker = this.projectPickerFoo.bind(this);
    }

    ngOnInit(): void {
        this.jira.getCustomData<CustomData>().subscribe(data => {
            this.customData = data;
            this.form.patchValue({
                projectId: this.customData.projectId
            });
        });

        this.form.get('issuePickerValue').valueChanges.subscribe(value => {
            if (value && typeof value === 'object' && value.id) {
                this.form.get('issueId').setValue(value.id);
                this.form.get('projectId').setValue(value.id);
            }
        });
        this.form.get('projectPickerValue').valueChanges.subscribe(value => {
            if (value && typeof value === 'object' && value.id) {
                this.form.get('projectId').setValue(value.id);
            }
        });
    }

    issuePickerFoo: SearchFunction = (term) => {
        let projectId: string;

        if (this.customData.source === 'PROJECT') {
            projectId = this.customData.projectId;
        }

        return this.jira.issuePicker(term, projectId).pipe(
            map(it => {
                const items = it.map(p => {
                        return {
                            id: p.id,
                            text: p.key + ' ' + p.summaryText,
                        };
                    }
                );
                return {
                    results: items,
                    pagination: {more: false}
                };
            })
        );
    }

    projectPickerFoo: SearchFunction = (term) => {
        return this.jira.searchProjects(term).pipe(
            map((response: ProjectSearchResponse) => ({
                results: response.values.map((p: Project) => ({
                    id: p.id,
                    text: `[${p.key}] ${p.name}`,
                    imageUrl: p.avatarUrls['16x16']
                })),
                pagination: {
                    more: false
                }
            }))
        );
    }

    onTypeChange(selectedType: string) {
        this.setValidators(selectedType);
    }

    submitForm() {
        this.createNewShare(this.form.value);
    }

    close() {
        this.jira.closeDialog();
    }

    createNewShare(formValue) {
        this.isCreateButtonDisabled = true;
        let projectIdObs = of(formValue.projectId as string);
        if (formValue.type === 'ISSUE') {
            projectIdObs = this.jira.getIssue(formValue.issueId)
                .pipe(map(issue => issue.fields.project.id));
        }
        projectIdObs.subscribe(projectId => {
            this.item.createShare({
                projectId,
                issueId: formValue.issueId,
                type: formValue.type,
            }).pipe(
                finalize(() => {
                    this.isCreateButtonDisabled = false;
                })
            ).subscribe(
                link => {
                    this.jira.closeDialog();
                    this.jira.emitEvent('share-update', JSON.stringify(link));
                    this.jira.showDialog({
                        key: 'link-edition-popup',
                        customData: {
                            uuid: link.uuid,
                            isNew: true
                        }
                    });
                }
            );
        });
    }

    private setValidators(type: string) {
        this.updateValidators('issueId', type === 'ISSUE');
        if (this.customData.source === 'GLOBAL') {
            this.updateValidators('projectId', type !== 'ISSUE');
        }
    }

    private updateValidators(controlName: string, condition: boolean) {
        if (condition) {
            this.form.get(controlName).setValidators([Validators.required]);
        } else {
            this.form.get(controlName).clearValidators();
        }
        this.form.get(controlName).updateValueAndValidity();
        this.form.get(controlName).markAsTouched();
    }

    shouldDisplayError(control: AbstractControl) {
        return Utils.shouldDisplayError(control);
    }
}


