import {Component, Input, OnInit} from '@angular/core';
import Utils from '../../utils/utils';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {ServerDataSource} from '../../components/app-table.component';
import {Link, LinkFilterCriteria} from '../../base/link';
import {JiraService} from '../../jira.service';
import {switchMap} from 'rxjs/operators';
import {ItemService} from '../../base/item.service';
import {ListAppState} from '../issue-view/issue-view.component';

@Component({
    selector: 'app-share-table',
    templateUrl: './share-table.component.html',
    styleUrls: ['./share-table.component.css']
})
export class ShareTableComponent implements OnInit {

    @Input() config: ListAppState;

    filterForm = new FormGroup({
        includeInactive: new FormControl(false)
    });

    source = new ServerDataSource<Link, LinkFilterCriteria>(this.http);

    includeInactiveWasEnabled = false;

    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private item: ItemService,
                private jira: JiraService) {
    }

    ngOnInit(): void {
        const shareUpdate = this.jira.observeEvent('share-update');
        shareUpdate.subscribe(() => {
            this.source.refresh();
        });

        this.route.paramMap.subscribe(params => {
            const issueId = params.get('issueId');
            this.setupDatasource(issueId);
            this.setupForm();
        });
    }

    copyToClipboard(value: any) {
        Utils.copyToClipboard(value);
    }

    createNew() {
        let customData = {
            isNew: true
        };

        this.route.queryParamMap.pipe(
            switchMap(it => this.item.create(it.get('issueId'), it.get('projectId')))
        ).subscribe(link => {
            const uuid = link.uuid;
            customData = Object.assign(customData, {uuid});
            this.jira.emitEvent('share-update', JSON.stringify(link));
            this.jira.showDialog({
                    key: 'link-edition-popup',
                    customData
                }
            );
        });
    }

    getInactiveStatusTooltip(status): string {
        const messages = [];

        if (status.disabled) {
            messages.push('Disabled');
        }

        if (status.disabledByParent) {
            messages.push('Disabled by parent');
        }

        if (status.expired) {
            messages.push('Expired');
        }
        return messages.length ? messages.join(', ') : '';
    }

    private setupForm() {
        this.filterForm.patchValue({
            includeInactive: false
        });

        this.filterForm.valueChanges.subscribe(it => {
            if (it.includeInactive) {
                this.includeInactiveWasEnabled = true;
            }
        });
    }

    private setupDatasource(issueId: string) {
        this.source.reload({
            endPoint: `/api/share`,
            defaultSort: 'created',
            defaultSortOrder: 'desc',
            defaultLimit: 10,
            filter: {
                issueId,
                status: 'active'
            },
            headers: new HttpHeaders({
                'X-Operation-Name': 'Fetching shares',
            })
        });

        this.filterForm.valueChanges.subscribe(it => {
            this.source.updateFilter({
                issueId,
                query: it.includeInactive ? 'status: all' : 'status: active'
            });
        });
    }
}
