import {Component, OnInit} from '@angular/core';
import {Observable, of, throwError, zip} from 'rxjs';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PeriodEnum} from '../base/period';
import {JiraService} from '../jira.service';
import {finalize, map, switchMap, tap} from 'rxjs/operators';
import {AppService} from '../app.service';
import {ProjectSchemaViewService} from './project-schema-view.service';
import Utils from '../utils/utils';
import {Config, ConfigView, GlobalConfigView} from '../base/config';
import {LoaderService} from '../loader.service';
import {ConfigEnabled} from './config-enabled';
import CustomValidators from '../utils/custom-validators';

interface CustomData {
    isNew?: boolean;
    type?: 'DEFAULT' | 'GLOBAL' | 'PROJECT' | 'SHARE';
    id?: string;
    projectId?: string;
}

interface ProjectSchemaView {
    data: CustomData;
    schema: ConfigView;
}

const initDataOptionDisabled: ConfigEnabled = {
    general: true,
    dataOptions: {
        publicShareLinkEnabled: {off: true, enable: true},
        comments: {off: true, show: true, add: true},
        internalComments: {off: true, show: true, add: true},
        attachments: {off: true, show: true, add: true},
        publicAttachments: {off: true, add: true},
        linkedIssue: {off: true, show: true, add: true},
        subtask: {off: true, show: true, add: true},
        statusTransition: {off: true, on: true},
        priorityEdit: {off: true, on: true},
        worklog: {off: true, on: true},
        webLinks: {off: true, on: true},
        changelog: {off: true, on: true},
        approval: {off: true, on: true},
        showSmartCheckList: {off: true, on: true},
        showIssueChecklist: {off: true, on: true},
        summaryEdit: {off: true, on: true},
        descriptionEdit: {off: true, on: true},
        issueCreate: {off: true, on: true},
        password: {off: true, on: true},
        expiration: {off: true, on: true},
        selectedUsers: {off: true, enable: true, notify: true}
    },
    security: {
        expiration: {optional: true, required: true, custom: true},
        password: {optional: true, required: true},
        selectedUsers: {optional: true, required: true},
        samlLogin: {optional: true, required: true, disabled: true},
        commentsWithRestrictions: {off: true, on: true}
    },
    issue: {
        status: true,
        priority: true,
        assignee: true,
        reporter: true,
        labels: true,
        dueDate: true,
        timeTracking: true,
        originalEstimate: true,
        fixVersions: true,
        versions: true,
        components: true,
        customFields: true
    },
    filter: {
        project: true,
        issueKey: true,
        summary: true,
        description: true,
        assignee: true,
        priority: true,
        status: true,
        createdDate: true,
        updatedDate: true,
        reporter: true,
        labels: true,
        dueDate: true,
        timeTracking: true,
        originalEstimate: true,
        fixVersions: true,
        versions: true,
        components: true,
        customFields: true
    }
};

@Component({
    selector: 'app-project-schema-config',
    templateUrl: './project-schema-form.component.html',
    styleUrls: ['./project-schema-form.component.css']
})
export class ProjectSchemaFormComponent implements OnInit {
    private refreshEventName = 'config-update';
    private refreshDefaultEventName = 'default-config-update';
    config$: Observable<ProjectSchemaView>;
    enabled = initDataOptionDisabled;
    tab = 'general';
    smartCheckListFieldVisible;
    issueCheckListFieldVisible;

    form: FormGroup = this.fb.group({

        name: ['', Validators.required],
        description: [''],

        enabled: [true],
        associatedProjects: [[]],

        expirationType: ['OPTIONAL'],
        expirationPeriod: [{count: 1, unit: PeriodEnum.WEEK}], // Expiration unit + count (SELECTABLE)

        expirationDefault: ['on'],
        expirationDefaultPeriod: [{count: 1, unit: PeriodEnum.WEEK}], // Expiration unit + count (SELECTABLE)

        password: ['OPTIONAL'],
        passwordDefault: ['on'],

        namedShareLinkEnabled: ['enable'],
        publicShareLinkDefault: ['enable'],

        comments: ['add'],
        commentsDefault: ['add'],

        internalComments: ['add'],
        internalCommentsDefault: ['add'],

        attachments: ['add'],
        attachmentsDefault: ['add'],

        linkedIssue: ['add'],
        linkedIssueDefault: ['add'],

        subtask: ['add'],
        subtaskDefault: ['add'],

        publicAttachments: ['add'],
        publicAttachmentsDefault: ['add'],

        statusTransition: ['off'],
        statusTransitionDefault: ['off'],

        worklog: ['off'],
        worklogDefault: ['off'],

        webLinks: ['off'],
        webLinksDefault: ['off'],

        changelog: ['off'],
        changelogDefault: ['off'],

        approval: ['off'],
        approvalDefault: ['off'],

        showSmartCheckList: ['off'],
        showIssueChecklist: ['off'],

        summaryEdit: ['off'],
        summaryEditDefault: ['off'],
        descriptionEdit: ['off'],
        descriptionEditDefault: ['off'],
        issueCreate: ['off'],
        commentsWithRestrictions: ['off'],

        priorityEdit: ['off'],
        priorityEditDefault: ['off'],

        defaultSharedIssueName: [''],
        defaultSharedJqlName: [''],
        defaultSharedBoardName: [''],
        defaultSharedRoadmapName: [''],

        sfStatus: [true],
        sfPriority: [true],
        sfAssignee: [true],
        sfReporter: [true],
        sfLabels: [true],
        sfDueDate: [true],
        sfTimeTracking: [true],
        sfOriginalEstimate: [true],
        sfFixVersions: [true],
        sfVersions: [true],
        sfComponents: [true],
        sfCustomFields: [true],

        ffProject: [true],
        ffIssueKey: [true],
        ffSummary: [true],
        ffDescription: [true],
        ffAssignee: [true],
        ffPriority: [true],
        ffStatus: [true],
        ffCreatedDate: [true],
        ffUpdatedDate: [true],
        ffReporter: [true],
        ffLabels: [true],
        ffDueDate: [true],
        ffTimeTracking: [true],
        ffOriginalEstimate: [true],
        ffFixVersions: [true],
        ffVersions: [true],
        ffComponents: [true],
        ffCustomFields: [true],
        filterCustomFields: [[]],
        issueCustomFields: [[]],

        selectedUsers: ['OPTIONAL'],
        allowedSelectedUsers: [''],
        allowedSelectedUsersEmailDomains: ['', [CustomValidators.emailDomains()]],
        selectedUsersDefault: ['notify'],
        samlLogin: ['DISABLED']
    });

    constructor(private fb: FormBuilder,
                private app: AppService,
                private psv: ProjectSchemaViewService,
                private loader: LoaderService,
                private jira: JiraService) {
    }

    ngOnInit(): void {
        this.config$ = Utils.require(this.jira.getCustomData<CustomData>(),
            'ProjectSchemaFormComponent', 'ngOnInit', 'this.jira.getCustomData<CustomData>()')
            .pipe(
                switchMap(data => zip(
                    of(data),
                    Utils.require(this.createOrLoad(data),
                        'ProjectSchemaFormComponent', 'ngOnInit', `this.createOrLoad(${JSON.stringify(data)})`)
                )),
                map(([data, schema]) => {
                    return {data, schema};
                }),
                tap((x: ProjectSchemaView) => {
                    const f = this.form;
                    const view = x.schema;
                    const item = view.item;
                    const config = item.config;
                    const ifc = config.issueFieldConfig;
                    const ffc = config.filterFieldConfig;
                    const fieldDefaults = config.fieldDefaults;

                    this.smartCheckListFieldVisible = ifc.smartCheckListOptionVisible;
                    this.issueCheckListFieldVisible = ifc.issueCheckListOptionVisible;

                    const formValue: any = {
                        id: ('id' in item) ? item.id : '',
                        name: config.name,
                        description: config.description,
                        enabled: config.enabled,
                        associatedProjects: ('associatedProject' in view) ? view.associatedProject : [],
                        filterCustomFields: ('filterCustomFieldsDisplay' in view) ? view.filterCustomFieldsDisplay : [],
                        issueCustomFields: ('issueCustomFieldsDefault' in view) ? view.issueCustomFieldsDefault : [],

                        namedShareLinkEnabled: ifc.namedShareLinkEnabled ? 'enable' : 'off',
                        publicShareLinkDefault: fieldDefaults.publicShareLink ? 'enable' : 'off',

                        comments: ifc.commentsAllowAdd ? 'add' : (ifc.commentsShow ? 'show' : 'off'),
                        commentsDefault: fieldDefaults.commentsAdd ? 'add' : (fieldDefaults.commentsShow ? 'show' : 'off'),

                        internalComments: ifc.commentsInternalAllowAdd ? 'add' : (ifc.commentsInternalShow ? 'show' : 'off'),
                        internalCommentsDefault: fieldDefaults.internalCommentsAdd ? 'add' :
                            (fieldDefaults.internalCommentsShow ? 'show' : 'off'),

                        attachments: ifc.attachmentsAllowAdd ? 'add' : (ifc.attachmentsShow ? 'show' : 'off'),
                        attachmentsDefault: fieldDefaults.attachmentsAdd ? 'add' : (fieldDefaults.attachmentsShow ? 'show' : 'off'),

                        linkedIssue: ifc.linkedIssuesContentShow ? 'add' : (ifc.linkedIssuesShow ? 'show' : 'off'),
                        linkedIssueDefault: fieldDefaults.linkedIssueShow ? 'share' : (fieldDefaults.linkedIssueList ? 'list' : 'off'),

                        subtask: ifc.subtasksContentShow ? 'add' : (ifc.subtasksShow ? 'show' : 'off'),
                        subtaskDefault: fieldDefaults.subtaskIssueShow ? 'share' : (fieldDefaults.subtaskIssueList ? 'list' : 'off'),

                        publicAttachments: ifc.attachmentsPublicAllowAdd ? 'add' : 'off',
                        publicAttachmentsDefault: fieldDefaults.publicAttachmentsAdd ? 'add' : 'off',

                        statusTransition: ifc.statusTransitionOn ? 'on' : 'off',
                        statusTransitionDefault: fieldDefaults.statusTransition ? 'on' : 'off',

                        worklog: ifc.showWorklog ? 'on' : 'off',
                        worklogDefault: fieldDefaults.worklog ? 'on' : 'off',

                        webLinks: ifc.showWebLinks ? 'on' : 'off',
                        webLinksDefault: fieldDefaults.webLinks ? 'on' : 'off',

                        changelog: ifc.showChangelog ? 'on' : 'off',
                        changelogDefault: fieldDefaults.changelog ? 'on' : 'off',

                        approval: ifc.showApproval ? 'on' : 'off',
                        approvalDefault: fieldDefaults.approval ? 'on' : 'off',

                        summaryEdit: ifc.summaryAllowEdit ? 'on' : 'off',
                        summaryEditDefault: fieldDefaults.summaryEdit ? 'on' : 'off',
                        descriptionEdit: ifc.descriptionAllowEdit ? 'on' : 'off',
                        descriptionEditDefault: fieldDefaults.descriptionEdit ? 'on' : 'off',
                        issueCreate: ifc.newIssueAllowCreate ? 'on' : 'off',
                        commentsWithRestrictions: config.securityConfig.displayCommentsWithRestrictions ? 'on' : 'off',

                        priorityEdit: ifc.priorityAllowEdit ? 'on' : 'off',
                        priorityEditDefault: fieldDefaults.priorityEdit ? 'on' : 'off',

                        showSmartCheckList: ifc.showSmartChecklist && this.smartCheckListFieldVisible ? 'on' : 'off',
                        showSmartChecklistDefault: fieldDefaults.showSmartChecklist ? 'on' : 'off',
                        showIssueChecklist: ifc.showIssueChecklist && this.issueCheckListFieldVisible ? 'on' : 'off',
                        showIssueChecklistDefault: fieldDefaults.showIssueChecklist ? 'on' : 'off',

                        defaultSharedIssueName: fieldDefaults.shareNamesDefaults.sharedIssueName,
                        defaultSharedJqlName: fieldDefaults.shareNamesDefaults.sharedJqlName,
                        defaultSharedRoadmapName: fieldDefaults.shareNamesDefaults.sharedRoadmapName,
                        defaultSharedBoardName: fieldDefaults.shareNamesDefaults.sharedBoardName,

                        sfStatus: ifc.statusShow,
                        sfPriority: ifc.priorityShow,
                        sfAssignee: ifc.assigneeShow,
                        sfReporter: ifc.reporterShow,
                        sfLabels: ifc.labelsShow,
                        sfDueDate: ifc.dueDateShow,
                        sfTimeTracking: ifc.timeTrackingShow,
                        sfOriginalEstimate: ifc.originalEstimateShow,
                        sfFixVersions: ifc.fixVersionsShow,
                        sfVersions: ifc.versionsShow,
                        sfComponents: ifc.componentsShow,
                        sfCustomFields: ifc.customFieldsShow,

                        ffProject: ffc.projectShow,
                        ffIssueKey: ffc.issueKeyShow,
                        ffSummary: ffc.summaryShow,
                        ffDescription: ffc.descriptionShow,
                        ffAssignee: ffc.assigneeShow,
                        ffPriority: ffc.priorityShow,
                        ffStatus: ffc.statusShow,
                        ffCreatedDate: ffc.createdDateShow,
                        ffUpdatedDate: ffc.updatedDateShow,
                        ffReporter: ffc.reporterShow,
                        ffLabels: ffc.labelsShow,
                        ffDueDate: ffc.dueDateShow,
                        ffTimeTracking: ffc.timeTrackingShow,
                        ffOriginalEstimate: ffc.originalEstimateShow,
                        ffFixVersions: ffc.fixVersionsShow,
                        ffVersions: ffc.versionsShow,
                        ffComponents: ffc.componentsShow,
                        ffCustomFields: ffc.customFieldsShow,

                        expirationType: config.securityConfig.expirationType,
                        expirationPeriod: {
                            unit: config.securityConfig.expirationUnit,
                            count: config.securityConfig.expirationCount
                        },
                        expirationDefault: fieldDefaults.expiration ? 'on' : 'off',
                        expirationDefaultPeriod: {
                            count: fieldDefaults.expiration ? fieldDefaults.expirationCount : 1,
                            unit: fieldDefaults.expiration ? fieldDefaults.expirationUnit : PeriodEnum.WEEK
                        },

                        password: config.securityConfig.password,
                        passwordDefault: fieldDefaults.password ? 'on' : 'off',

                        selectedUsers: config.securityConfig.selectedUsers,
                        allowedSelectedUsers: config.securityConfig.allowedSelectedUsers,
                        allowedSelectedUsersEmailDomains: config.securityConfig.allowedSelectedUsersEmailDomains,
                        selectedUsersDefault: fieldDefaults.selectedUsersNotify ? 'notify' :
                            (fieldDefaults.selectedUsersShare ? 'enable' : 'off'),
                        samlLogin: config.securityConfig.samlDisabled ? 'DISABLED' : config.securityConfig.samlLogin
                    };

                    this.enabled.security.samlLogin = {
                        optional: !config.securityConfig.samlDisabled,
                        required: !config.securityConfig.samlDisabled,
                        disabled: !config.securityConfig.samlDisabled
                    };

                    if (this.isDefaultView(x.data)) {
                        this.form.get('name').setValidators([]);
                    }

                    this.form.get('enabled').valueChanges.subscribe(it => {
                        this.psv.emitChange(it);
                    });

                    function toggle(val, show, add) {
                        return add ? val : ((show && val === 'show') ? 'show' : 'off');
                    }

                    this.form.patchValue(formValue);
                })
            );
    }

    private getConfig(x: ProjectSchemaView): Config {
        const v = this.form.getRawValue();
        const filterCustomFieldsDisplay: string[] = [];
        const issueCustomFieldsDefault: string[] = [];
        for (const customField of v.filterCustomFields) {
            filterCustomFieldsDisplay.push(customField.id);
        }
        for (const customField of v.issueCustomFields) {
            issueCustomFieldsDefault.push(customField.id);
        }

        return {
            name: v.name,
            description: v.description,
            enabled: v.enabled,
            issueFieldConfig: {
                namedShareLinkEnabled: v.namedShareLinkEnabled === 'enable',
                commentsShow: v.comments === 'add' ? true : v.comments === 'show',
                commentsAllowAdd: v.comments === 'add',
                commentsInternalShow: v.internalComments === 'add' ? true : v.internalComments === 'show',
                commentsInternalAllowAdd: v.internalComments === 'add',
                attachmentsShow: v.attachments === 'add' ? true : v.attachments === 'show',
                attachmentsAllowAdd: v.attachments === 'add',
                attachmentsPublicAllowAdd: v.publicAttachments === 'add',
                subtasksShow: v.subtask === 'add' ? true : v.subtask === 'show',
                subtasksContentShow: v.subtask === 'add',
                linkedIssuesShow: v.linkedIssue === 'add' ? true : v.linkedIssue === 'show',
                linkedIssuesContentShow: v.linkedIssue === 'add',
                statusShow: v.sfStatus,
                statusTransitionOn: v.statusTransition === 'on',
                showWorklog: v.worklog === 'on',
                showWebLinks: v.webLinks === 'on',
                showChangelog: v.changelog === 'on',
                showApproval: v.approval === 'on',
                showSmartChecklist: v.showSmartCheckList === 'on',
                showIssueChecklist: v.showIssueChecklist === 'on',
                summaryAllowEdit: v.summaryEdit === 'on',
                descriptionAllowEdit: v.descriptionEdit === 'on',
                newIssueAllowCreate: v.issueCreate === 'on',
                priorityAllowEdit: v.priorityEdit === 'on',
                priorityShow: v.sfPriority,
                assigneeShow: v.sfAssignee,
                reporterShow: v.sfReporter,
                labelsShow: v.sfLabels,
                dueDateShow: v.sfDueDate,
                timeTrackingShow: v.sfTimeTracking,
                originalEstimateShow: v.sfOriginalEstimate,
                fixVersionsShow: v.sfFixVersions,
                versionsShow: v.sfVersions,
                componentsShow: v.sfComponents,
                customFieldsShow: v.sfCustomFields,
                customFieldsDisplay: [],
                customFieldsDefault: issueCustomFieldsDefault,
            },
            filterFieldConfig: {
                projectShow: v.ffProject,
                issueKeyShow: v.ffIssueKey,
                summaryShow: v.ffSummary,
                descriptionShow: v.ffDescription,
                assigneeShow: v.ffAssignee,
                priorityShow: v.ffPriority,
                statusShow: v.ffStatus,
                createdDateShow: v.ffCreatedDate,
                updatedDateShow: v.ffUpdatedDate,
                reporterShow: v.ffReporter,
                labelsShow: v.ffLabels,
                dueDateShow: v.ffDueDate,
                timeTrackingShow: v.ffTimeTracking,
                originalEstimateShow: v.ffOriginalEstimate,
                fixVersionsShow: v.ffFixVersions,
                versionsShow: v.ffVersions,
                componentsShow: v.ffComponents,
                customFieldsShow: v.ffCustomFields,
                customFieldsDisplay: filterCustomFieldsDisplay,
                customFieldsDefault: [],
            },
            fieldDefaults: {
                publicShareLink: v.publicShareLinkDefault === 'enable',
                commentsShow: v.commentsDefault === 'add' ? true : v.commentsDefault === 'show',
                commentsAdd: v.commentsDefault === 'add',
                attachmentsShow: v.attachmentsDefault === 'add' ? true : v.attachmentsDefault === 'show',
                attachmentsAdd: v.attachmentsDefault === 'add',
                linkedIssueList: v.linkedIssueDefault === 'share' ? true : v.linkedIssueDefault === 'list',
                linkedIssueShow: v.linkedIssueDefault === 'share',
                subtaskIssueList: v.subtaskDefault === 'share' ? true : v.subtaskDefault === 'list',
                subtaskIssueShow: v.subtaskDefault === 'share',
                statusTransition: v.statusTransitionDefault === 'on',
                worklog: v.worklogDefault === 'on',
                webLinks: v.webLinksDefault === 'on',
                changelog: v.changelogDefault === 'on',
                approval: v.approvalDefault === 'on',
                summaryEdit: v.summaryEditDefault === 'on',
                descriptionEdit: v.descriptionEditDefault === 'on',
                showSmartChecklist: v.showSmartCheckList === 'on',
                showIssueChecklist: v.showIssueChecklist === 'on',
                internalCommentsShow: v.internalCommentsDefault === 'add' ? true : v.internalCommentsDefault === 'show',
                internalCommentsAdd: v.internalCommentsDefault === 'add',
                publicAttachmentsAdd: v.publicAttachmentsDefault === 'add',
                priorityEdit: v.priorityEditDefault === 'on',
                expiration: v.expirationDefault === 'on',
                expirationUnit: v.expirationDefaultPeriod.unit,
                expirationCount: v.expirationDefaultPeriod.count,
                password: v.passwordDefault === 'on',
                selectedUsersShare: v.selectedUsersDefault === 'notify' ? true : v.selectedUsersDefault === 'enable',
                selectedUsersNotify: v.selectedUsersDefault === 'notify',
                shareNamesDefaults: {
                    sharedIssueName: v.defaultSharedIssueName,
                    sharedJqlName: v.defaultSharedJqlName,
                    sharedRoadmapName: v.defaultSharedRoadmapName,
                    sharedBoardName: v.defaultSharedBoardName
                }
            },
            securityConfig: {
                expirationType: v.expirationType,
                password: v.password,
                expirationUnit: v.expirationPeriod.unit,
                expirationCount: v.expirationPeriod.count,
                selectedUsers: v.selectedUsers,
                allowedSelectedUsers: v.allowedSelectedUsers,
                allowedSelectedUsersEmailDomains: v.allowedSelectedUsersEmailDomains,
                samlLogin: v.samlLogin,
                displayCommentsWithRestrictions: v.commentsWithRestrictions === 'on'
            }
        };
    }

    saveAndExit(x: ProjectSchemaView) {
        this.update(x).subscribe(() => {
            if (this.isDefaultView(x.data)) {
                this.jira.emitEvent(this.refreshDefaultEventName, JSON.stringify(x.schema));
            } else {
                this.jira.emitEvent(this.refreshEventName, JSON.stringify(x.schema));
            }
            this.close(x);
        });
    }

    private isOptionsFormValid(): boolean {
        Utils.validateAllFormFields(this.form);
        return this.form.valid;
    }

    update(x: ProjectSchemaView) {
        if (this.isOptionsFormValid()) {
            const v = this.form.getRawValue();
            const config = this.getConfig(x);
            if (this.isDefaultView(x.data)) {
                return this.app.updateDefaultConfig({item: {config}});
            } else if (this.isGlobalView(x.data)) {
                const projectIds = v.associatedProjects.map(it => it.id);
                const view = x.schema as GlobalConfigView;
                return this.app.updateGlobalConfig({item: {id: view.item.id, projectIds, config}});
            }
        }
        return of(undefined);
    }

    close(x: ProjectSchemaView) {
        this.jira.closeDialog(x.schema.item);
    }

    /* Utils */
    shouldDisplayError(control: AbstractControl): boolean {
        return control.invalid && (control.dirty || control.touched);
    }

    createOrLoad(customData: CustomData): Observable<ConfigView> {
        // 'Create External Share Link' from hamburger menu '...' in jira cloud
        // We get issueId in URL. Lets create new share from that.
        customData = customData || {};
        if (this.isDefaultView(customData)) {
            return this.app.getDefaultConfig();
        } else if (this.isGlobalView(customData)) {
            if (customData.isNew) {
                return this.app.createGlobalConfig().pipe(tap((scheme) => {
                        this.jira.emitEvent(this.refreshEventName, JSON.stringify(scheme));
                    }),
                    switchMap((scheme) => {
                        return this.app.getGlobalConfig(scheme.item.id);
                    }));
            }
            if (customData.id) {
                return this.app.getGlobalConfig(customData.id);
            }
        }

        return throwError('Failed to handle init data in project config form ' + customData);
    }

    confirmDeletion(x: ProjectSchemaView) {
        this.jira.showDialog({
                key: 'confirm-delete',
                customData: {
                    title: 'Delete Project Config?',
                }
            }, (result) => {
                if (result.deleted) {
                    this.loader.fullscreen(true);

                    const view = x.schema as GlobalConfigView;
                    this.app.deleteGlobalConfig([view.item.id])
                        .pipe(finalize(() => this.loader.fullscreen(false)))
                        .subscribe(() => {
                            this.jira.emitEvent(this.refreshEventName);
                            this.close(x);
                        });
                }
            }
        );
    }

    isDefaultView(data: CustomData) {
        return data.type === 'DEFAULT';
    }

    isGlobalView(data: CustomData) {
        return data.type === 'GLOBAL';
    }

    isDefaultsInvalid() {
        return this.isInvalid('expirationDefaultPeriod');
    }

    private isInvalid(name: string): boolean {
        const control = this.form.get(name);
        return this.isTouchedOrDirty(control) && control.invalid;
    }

    private isTouchedOrDirty(control: AbstractControl) {
        return control.dirty || control.touched;
    }
}
