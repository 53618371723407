import {Component, Input, OnInit, forwardRef, Optional} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    FormBuilder,
    FormGroup,
    AbstractControl, ControlContainer
} from '@angular/forms';
import {SearchInput} from '../../../components/better-select/better-select.component';
import Utils from '../../../utils/utils';

@Component({
    selector: 'app-share-editor-dynamic-select',
    templateUrl: './share-editor-dynamic-select.component.html',
    styleUrls: ['./share-editor-dynamic-select.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ShareEditorDynamicSelectComponent),
            multi: true
        }
    ]
})
export class ShareEditorDynamicSelectComponent implements OnInit, ControlValueAccessor {
    @Input() label: string;
    @Input() search: SearchInput;
    @Input() multiple = false;
    @Input() placeholder = 'Search';
    @Input() minimumInputLength = 0;
    @Input() required = false;
    @Input() warningMessages: { [key: string]: string };
    @Input() formControlName: string;
    @Input() info: string;
    @Input() clearable = false;
    @Input() additionalInfo: string;

    form: FormGroup;
    warningMessage = '';

    private onChange = (_: any) => {};
    protected onTouched = () => {};

    constructor(@Optional() private controlContainer: ControlContainer,
                private fb: FormBuilder) {
        this.form = this.fb.group({
            selectedItem: []
        });
    }

    ngOnInit(): void {
        this.form.get('selectedItem').valueChanges.subscribe(value => {
            this.onChange(value);
        });

        const control = this.getControl();
        if (control) {
            control.statusChanges.subscribe(() => {
                this.updateWarningMessage(control);
            });
            this.updateWarningMessage(control);
        }
    }

    writeValue(value: any): void {
        this.form.get('selectedItem').setValue(value, { emitEvent: false });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.form.disable() : this.form.enable();
    }

    shouldDisplayWarning(): boolean {
        return this.warningMessage && Utils.shouldDisplayError(this.getControl());
    }

    private getControl(): AbstractControl {
        const formGroup = this.controlContainer.control as FormGroup;
        return formGroup.get(this.formControlName);
    }

    private updateWarningMessage(control: AbstractControl): void {
        this.warningMessage = Utils.updateWarnMessage(control, this.warningMessages);
    }
}
